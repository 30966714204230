import React from "react"

import Image26 from "../../assets/images/Image26.svg"
import Image27 from "../../assets/images/image27.svg"
import Image30 from "../../assets/images/Image30.svg"

import styles from "./styles.module.css"

const CardinalPromises = () => {
  return (
    <section className={styles.container}>
      <p className={styles.hero__section__text}>Our 3 Cardinal Promises</p>

      <div className={styles.content}>
        <div className={styles.contentItem}>
          <img src={Image26} alt="new Icon" className={styles.contentImg} />
          <p className={styles.contentTitle}>Increased Basket Size</p>

          <p className={styles.smallText}>Experience increased spending in your business as people tend to spend more when 
            they realize they earn discounts whey they spend.</p>
        </div>
        <div className={styles.contentItem}>
          <img src={Image27} alt="new Icon" className={styles.contentImg} />
          <p className={styles.contentTitle}>Increased Customer Loyalty</p>

          <p className={styles.smallText}>
          Your customers stay with you, new ones are attracted to your business simply because 
          you offer them the best deals for their spending.
          </p>
        </div>
        <div className={styles.contentItem}>
          <img src={Image30} alt="new Icon" className={styles.contentImg} />
          <p className={styles.contentTitle}>Increased Customer Base</p>

          <p className={styles.smallText}>
          With all of the benefits on offer, your customer base grows exponentially.
          </p>
        </div>
      </div>
    </section>
  )
}

export default CardinalPromises