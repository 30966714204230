import React from "react"
import styles from "./styles.module.css"
import GasIcon from "../../assets/images/GasIcon.svg"
import FillStation from "../../assets/images/fillStationIcon.svg"
import DiagIcon from "../../assets/images/diagIcon.svg"
import CinemaIcon from "../../assets/images/cinemas.svg"
import Marketicon from "../../assets/images/marketSuper.svg"
import ResIocn from "../../assets/images/resIcon.svg"
import WholeFod from "../../assets/images/wholeFood.svg"
import Pharm from "../../assets/images/pharm.svg"
import Gadgets from "../../assets/images/gadgets.svg"
import fitness from "../../assets/images/fitness.svg"
import kids from "../../assets/images/kids.svg"
import EyeIcon from "../../assets/images/eye.svg"
import dentalIcon from "../../assets/images/dental.svg"
import hospital from "../../assets/images/hospital.svg"
import fashion from "../../assets/images/fashion.svg"
// import Allocn from "../../assets/images/All.svg"
import Allocn from "../../assets/images/All.svg"

const BusinessCategory = () => {

  return (
    <section className={styles.containerMain}>
    <div className={styles.container}>
      <p className={styles.join__postpaid__text}>Business Categories<br /> & Applicable Discounts</p>

      <div className={styles.innerHeight}></div>

      <div className={styles.cardContainer}>
        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={GasIcon} alt="gas Icon" />
            <p className={styles.cardtext}>Gas Station</p>
          </div>

          <p className={styles.percentText}>10%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={FillStation} alt="gas Icon" />
            <p className={styles.cardtext}>Filling Station</p>
          </div>

          <p className={styles.percentText}>15%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={DiagIcon} alt="gas Icon" />
            <p className={styles.cardtext}>Lab/Diagonostics</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={CinemaIcon} alt="gas Icon" />
            <p className={styles.cardtext}>Cinemas</p>
          </div>

          <p className={styles.percentText}>20%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={Marketicon} alt="gas Icon" />
            <p className={styles.cardtext}>Super Markets</p>
          </div>

          <p className={styles.percentText}>30%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={ResIocn} alt="gas Icon" />
            <p className={styles.cardtext}>Restaurants</p>
          </div>

          <p className={styles.percentText}>30%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={WholeFod} alt="gas Icon" />
            <p className={styles.cardtext}>Wholesale Foods</p>
          </div>

          <p className={styles.percentText}>10%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={Pharm} alt="gas Icon" />
            <p className={styles.cardtext}>Pharmacy</p>
          </div>

          <p className={styles.percentText}>10%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={Gadgets} alt="gas Icon" />
            <p className={styles.cardtext}>Gadgets</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={fitness} alt="gas Icon" />
            <p className={styles.cardtext}>Fitness</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={kids} alt="gas Icon" />
            <p className={styles.cardtext}>Kids</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={EyeIcon} alt="gas Icon" />
            <p className={styles.cardtext}>Eye Care</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={dentalIcon} alt="gas Icon" />
            <p className={styles.cardtext}>Dental</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={hospital} alt="gas Icon" />
            <p className={styles.cardtext}>Hospitals</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={fashion} alt="gas Icon" />
            <p className={styles.cardtext}>Fashion & Lifestyle</p>
          </div>

          <p className={styles.percentText}>25%</p>
        </div>

        <div className={styles.cardItem}>
          <div>
            <img className={styles.image} src={Allocn} alt="gas Icon" />
            <p className={styles.cardtext}>All</p>
          </div>

          <p className={styles.percentText}>50%</p>
        </div>
      </div>

    </div>
    </section>
  )
}

export default BusinessCategory

