import React from "react";
import { useNavigate } from "react-router-dom";
import groupLogo from "../../assets/images/logoGroup.svg"
import logoSide from "../../assets/images/forgotPass.svg"
import OTPInput from "../../components/OTPInput/OTPInput";
import styles from "./styles.module.css"

const OTPPage = () => {

  const handleOtpComplete = (otp) => {
    // You can now validate the OTP or send it to your backend
  };

  const navigate = useNavigate()

  const goHome = () => {
    navigate("/")
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={goHome}>
        <img src={groupLogo} alt="Logo" />
      </div>

      <div className={styles.containerTwo}>
        <div className={styles.sideLogoContainer}>
          <img height={600} src={logoSide} alt="Logo" />
        </div>

        <div className={styles.formContainer}>
          <div className={styles.formInner}>
            <div className={styles.textContainer}>
              <p className={styles.textOne}>Enter Otp</p>
              <p className={styles.textTwo}>
                Please enter the 6-digit code sent to your mobile number +234 70X-XXX-XXXX
              </p>
            </div>

            <OTPInput length={6} onComplete={handleOtpComplete} />

            <p className={styles.textFour}>
              Didn't get code? <span className={styles.spanText}>Resend code</span>
            </p>

            <button className={styles.btn} onClick={() => navigate('/reset-password')}>Verify OTP</button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default OTPPage;

