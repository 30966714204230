import React from 'react';
import styles from "./styles.module.css";
import verify from "../../../../../assets/images/verify.svg";
import Graph from "../../../../../assets/images/Graph.svg";
import ChevLeft from "../../../../../assets/images/ChevLeft.svg";
import ChevRight from "../../../../../assets/images/ChevRight.svg";
import MonthlyChart from "./MonthlyChart";
import DoughnutChart from './DoughnutChart';

const MainContent = () => {
    return (
        <div className={styles.businessmaincontent}>
            <div className={styles.businessmain__context}>
                <div className={styles.businessmain__cards}>
                    <p className={styles.businessmain__member}>Member Visit</p>
                    <p className={styles.businessmain__membercount}>15</p>

                </div>

                <div className={styles.businessmain__cards}>
                    <p className={styles.businessmain__member}>Stat</p>
                    <p className={styles.businessmain__membercount}>3,298</p>
                </div>

                <div className={styles.businessmain__cards}>
                    <p className={styles.businessmain__member}>Daily Profits</p>
                    <p className={styles.businessmain__membercount}>86%</p>
                    <img src={Graph} className={styles.graph} alt="graph__icon" />
                </div>

                <div className={styles.containerTwo}>
                    <div className={styles.leftSide}></div>

                    <div className={styles.containerFour}>
                        <img src={verify} alt="warning icon" />

                        <div className={styles.containerFive}>
                            <p className={styles.verifyText}>Complete Account Verification</p>
                            <p className={styles.uploadtext}>Upload your BVN to upgrade your account</p>
                        </div>
                    </div>
                    <button className={styles.btn}>Upload BVN</button>
                </div>
            </div>
            <div className={styles.businessmaincard__content}>
                <div className={styles.businessmaincard__holder}>
                    <div className={styles.businessmaincard__holder__text}>
                        <p className={styles.businessmaincard__holder__activity}>Activity</p>
                        <p className={styles.businessmaincard__holder__activity_i}>Month</p>
                    </div>
                    <hr className={styles.businessmaincard__hr}></hr>
                    <MonthlyChart />

                </div>

                <div className={styles.businessmaincard__year}>
                    <div className={styles.businessmaincard__year__text}>
                        <img src={ChevLeft} className={styles.chev__left} alt="left__icon" />
                        <p className={styles.year}>2024</p>
                        <img src={ChevRight} className={styles.chev__right} alt="right__icon" />
                    </div>

                    <DoughnutChart />

                </div>
            </div>

        </div>
    );
}

export default MainContent;