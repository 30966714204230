import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../assets/images/LeftArrow.svg";
import mobileCancel from "../../assets/images/mobileCancel.svg"
import styles from "./styles.module.css"
import { AppContext } from "../../context";

const SideDrawer = ({ isOpen, setIsOpen}) => {

  const closeDrawer = () => setIsOpen(false);

  const {pageNav, setPageNav, setOpenJoinNow} = useContext(AppContext)

  const handleNavClick = (navParam) => {
    setPageNav(navParam)
    closeDrawer()
  }

  const joinNow = () => {
    closeDrawer()
    setOpenJoinNow(true)
  }

  const navigate = useNavigate();


  const handleBus = () => {
    navigate('/business');
    closeDrawer();
  }

  const handleCos = () => {
    navigate('/');
    closeDrawer();
  }

  const goHome = () => {
    navigate('/');
    closeDrawer();
  }

  return (
    <>
      {/* Drawer */}
      <div className={isOpen ? styles.drawerOpen : styles.drawer}>
        <div className={styles.drawerContent}>
          <div className={styles.logoContainer}>
            <img src={mobileCancel} alt="cancel btn" onClick={closeDrawer} className={styles.logo} />
          </div>

          <div className={styles.backContainer} onClick={goHome}>
            <img src={LeftArrow} alt="Arrow Left" />
            <p className={styles.backText}>Home</p>
          </div>
          <div className={styles.containerText}>
            <p className={pageNav === "alloffers" ? styles.textOneActive : styles.textOne} onClick={() => handleNavClick("alloffers")}>All Offers</p>

            <p className={pageNav === "offers" ? styles.textOneActive : styles.textOne}
              onClick={() => handleNavClick("offers")}
            >Limited Time Offers</p>

            <p 
              className={pageNav === "gift" ? styles.textOneActive : styles.textOne} 
              onClick={() => handleNavClick("gift")}>
              Gifting
            </p>

            <p 
              className={pageNav === "holiday" ? styles.textOneActive : styles.textOne}
              onClick={() => handleNavClick("holiday")}
            >
              Holiday Special
            </p>

            <p 
              className={pageNav === "health" ? styles.textOneActive : styles.textOne}
              onClick={() => handleNavClick("health")}
              >
              Healthcare
            </p>

            <p className={pageNav === "help" ? styles.textOneActive : styles.textOne} onClick={() => handleNavClick("help")}>Help</p>
          </div>

          <div className={styles.container}>
            <div className={styles.textContainer}>
              <p className={styles.textThree} onClick={() => handleCos()}>Consumer</p>
              <p className={styles.textThree} onClick={() => handleBus()}>Business</p>
            </div>

            <div className={styles.btnContainer}>
              <button className={styles.btnLogin} onClick={joinNow}>Join Now</button>
              {/* <button className={styles.btnLogin} onClick={() => navigate("/login")}>Login</button>

              <button className={styles.btnLoginOutlined} onClick={() => navigate("/signup")}>Sign Up</button> */}
            </div>
          </div>
        </div>
      </div>

      {isOpen && <div className={styles.overlay} onClick={closeDrawer}>
        </div>}
    </>
  );
};

export default SideDrawer;
