import React from 'react';
import styles from "./styles.module.css";
import BusinessHeader from '../BusinessHeader/BusinessHeader';
import Sidebar from './VerifySidebar/Sidebar';
import Main from './VerifyMain/Main';

const Verify = () => {
    return (
        <div>
            <BusinessHeader />
            <div className={styles.verify__content}>
                <Sidebar />
                <Main />
            </div>
        </div>
    );
}

export default Verify;