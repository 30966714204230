import React, {useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css"

const Modal = ({ setOpenModal }) => {

  const ref = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogOut = () => {
    navigate('/login')
  }

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.containerTwo}>
        <p className={styles.textOne}>Are you sure you <br /> want to log out?</p>

        <div className={styles.btnContainer}>
          <button className={styles.btnContained} onClick={handleLogOut}>Log Out</button>

          <button onClick={() => setOpenModal(false)} className={styles.btnOutlined}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default Modal;