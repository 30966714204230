import React from "react";

import Checkout from "../../assets/images/Checkout.svg";
import Discount from "../../assets/images/DiscountIcon.svg";
import Payment from "../../assets/images/Payment.svg";
import styles from "./styles.module.css"


const ShopPostpaid = () => {
  return (
    <div className={styles.container}>
      <p className={styles.textOne}>How Postpaid Works</p>

      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <img
            src={Checkout}
            alt="At checkout"
            className="how-to-use-image"
          />
          <p className={styles.textTwo}>Checkout</p>

          <p className={styles.textThree}>
            At checkout, look for the Postpaid logo to use your discount code
          </p>
        </div>
        <div className={styles.card}>
          <img
            src={Discount}
            alt="PostPaid app"
            className="how-to-use-image"
          />
          <p className={styles.textTwo}>Discount</p>

          <p className={styles.textThree}>
            Identify yourself as a member and verify your discount code to be applied
          </p>
        </div>
        <div className={styles.card}>
          <img
            src={Payment}
            alt="The PostPaid Card"
            className="how-to-use-image"
          />
          <p className={styles.textTwo}>Discount</p>

          <p className={styles.textThree}>Checkout with Discount Earned Instantly.</p>
        </div>
      </div>
    </div>
  )
}

export default ShopPostpaid