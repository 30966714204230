import React, { useState, useRef, useEffect } from "react"
import styles from "./styles.module.css"


const Dropdown = ({lists, title}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(lists[0])

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (e, tempItem) => {
    e.stopPropagation();
    setSelected(tempItem)
    setOpen(false)
  }

  return (
    <div ref={ref} className={styles.container} onClick={() => setOpen(prev => !prev)}>
      <p className={styles.textOne}>{title}:</p>
      <p className={styles.textOne}>{selected}</p>

      {open && 
        <div className={styles.listContainer}>
          {
            lists.map((el, index) => 
              <p onClick={(e) => handleSelect(e, el)} className={styles.item} key={index}>{el}</p>
            )
          }
        </div>
      }
    </div>
  )
}

export default Dropdown;