import React, {useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import signupLogo from "../../assets/images/signupLogo.svg"
import DropDown from "../DropdownTwo/DropDown";
import { stateAndLGA } from "../../data/stateAndLGA.js";
import styles from "./styles.module.css"


const GENDERLIST = ["Female", "Male"]

const MoreInfo = ({setSteps}) => {
  const [email, setEmail] = useState('')
  const [dob, setDob] = useState('')

  const [checked, setChecked] = useState(false);
  const [agree, setAgree] = useState(false);
  const [state, setLocalState] = useState("")
  const [refCode, setRefCode] = useState("")
  const [gender, setGender] = useState("")
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);
  const [localGA, setLocalGA] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }


  const handleRefCodeChange = (e) => {
    setRefCode(e.target.value)
  }


  const handleDobChange = (e) => {
    setDob(e.target.value)
  }

  const navigate = useNavigate();

  useEffect(() => {
    let tempList = [];
    stateAndLGA.forEach((el) => {
      tempList.push(el.state);
    });
    setStateList(tempList);
    // setLocalState(tempList[0]);
  }, []);

  useEffect(() => {
    stateAndLGA.every((el) => {
      if (el.state === state) {
        setLgaList(el.lgas);
        setLocalGA(el.lgas[0]);
        return false;
      }
      return true;
    });
  }, [state]);


  return (
    <div className={styles.containerTwo}>
    <div className={styles.sideLogoContainer}>
      <img height={600} src={signupLogo} alt="Logo" />
    </div>

    <div className={styles.formContainer}>
      <div className={styles.formInner}>
        <div className={styles.textContainer}>
          <p className={styles.textOne}>A little more information</p>
        </div>

        <div className={styles.dropDownItem}>
          <DropDown
            label="Gender"
            data={GENDERLIST}
            value={gender}
            setValue={setGender}
          />
        </div>



        <input 
          placeholder="Date Of Birth"  
          onChange={handleDobChange}
          value={dob}
          className={styles.input}
          
          name="birthday"
          type="text"
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = e.target.value ? "date" : "text")}
        />
        
        <div className={styles.dropDownItem}>
          <DropDown
            label="State Of Residence"
            data={stateList.sort()}
            value={state}
            setValue={setLocalState}
          />
        </div>

        <div className={styles.dropDownItem}>
          <DropDown
            label="LGA"
            data={lgaList}
            value={localGA}
            setValue={setLocalGA}
          />
        </div>


        <input 
          placeholder="Address"  
          onChange={handleEmailChange}
          value={email}
          className={styles.input}
          type="email"
        />


        <input 
          placeholder="Referral Code"  
          onChange={handleRefCodeChange}
          value={refCode}
          className={styles.input}
          type="text"
        />


        <button className={styles.btn} onClick={() => navigate('/verify-email')}>Next</button>

      </div>
    </div>
  </div>
  )
}

export default MoreInfo