import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import homePics from "../../assets/images/homePic.svg";
import { AppContext } from "../../context";
import ActiveDis from "../ActiveDisCard/ActiveDis";
import TransactHisCard from "../TransactHisCard/TransactHistoryCard";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const Home = () => {
  const navigate = useNavigate();
  const { subscribed } = useContext(AppContext);

  const [userName, setUserName] = useState("Loading...");
  const [membershipId, setMembershipId] = useState("Loading...");
  const [nextPaymentDate, setNextPaymentDate] = useState("Loading...");
  const [discountEarned, setDiscountEarned] = useState(0);

  const auth = getAuth();
  const firestore = getFirestore();

  const goToPage = (url) => {
    navigate(url);
  };

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(`${userData.firstName || "Anonymous"} ${userData.lastName || ""}`.trim());
          setMembershipId(userData.membershipId || "N/A");

          // Set next payment date (dynamic or fallback)
          const paymentDate = userData.nextPaymentDate 
            ? new Date(userData.nextPaymentDate).toLocaleDateString() 
            : "N/A";
          setNextPaymentDate(paymentDate);

          // Calculate total discount earned
          const totalDiscount = userData.discountHistory?.reduce(
            (total, discount) => total + (discount.amount || 0),
            0
          );
          setDiscountEarned(totalDiscount || 0);
        } else {
          console.error("User document does not exist.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.mobileContainer}>
        <div>
          <p className={styles.textprofile}>{userName}</p>
          <p className={styles.idText}>Membership ID: {membershipId}</p>
        </div>

        <div className={styles.textContainer}>
          <p onClick={() => goToPage('/upgrade-plan')} className={styles.upgradetext}>
            Membership Plan <span className={styles.span}>Subscription</span>
          </p>
          <p className={styles.date}>Next Payment date is {nextPaymentDate}</p>
        </div>
      </div>

      {subscribed && (
        <div className={styles.containerThree}>
          <h1 className={styles.headertext}>Account Information</h1>
        </div>
      )}

      {subscribed && (
        <div className={styles.containerSix}>
          <div className={styles.cardContainer}>
            <div className={styles.discount}>
              <p className={styles.discountText}>Discount Earned</p>
              <p className={styles.moneyText}>
                &#8358; {discountEarned.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </p>
            </div>
            <div className={styles.earned}>
              <ActiveDis />
            </div>
          </div>

          <div className={styles.historyContainer}>
            <div className={styles.history}>
              <TransactHisCard />
            </div>

            <div className={styles.advert}>
              <img src={homePics} alt="home pics" className={styles.homePics} />
            </div>
          </div>
        </div>
      )}

      <div style={{ width: "10px", height: "330px" }}></div>
    </div>
  );
};

export default Home;