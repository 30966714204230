import React, { useState } from "react";
import styles from "./styles.module.css"
import Dropdown from "../Dropdown/Dropdown"
import Carousel from "../Carousels/Carousels";


const LISTONE = ["KDB", "Zidane", "Messi", "Pirlo", "Maldini", "Kante"]

const LISTTWO = ["Iniesta", "Xavi", "Ronaldo", "Ronaldinho", "John Terry", "Essien"]

const images = [
  "10%", "5%", "15%", "45%", "80%"
];

const TableItem = ({status}) => {
  const [checked, setChecked] = useState(false);

  const renderStatus = () => {
    switch (status) {
      case "Pending": 
        return <p className={styles.pendingText}>Pending</p>;
      case "Used":
        return <p className={styles.usedText}>Used</p>;
      case "Expired":
        return <p className={styles.expiredText}>Expired</p>;
      default:
        return <p>Unkown</p>  
    }
  }

  return (
    <div className={styles.tableContent}>
      <div className={styles.checkBoxTwo} onClick={() => setChecked(prev => !prev)}>
        {checked ? <div className={styles.boxBlue}></div> : <></> }
      </div>
      <p className={styles.contentTextTwo}>GDASJ2801NFDS</p>
      <p className={styles.contentTextTwo}>Rp1.300.000</p>
      <p className={styles.contentText}>Category</p>
      <p className={styles.contentText}>5%</p>
      <p className={styles.contextDate}>12-09-24</p>
      <div className={styles.contextDate}>
        {renderStatus()}
      </div>

      <p className={styles.tableAction}>Action</p>
    </div>
  )
}

const TransactionHistory = () => {

  return (
    <div className={styles.container}>
      <h1 className={styles.headertext}>Transaction History</h1>
      
      <div className={styles.containerTwo}>
        <Dropdown title="Timeframe" lists={LISTONE} /> 

        <Dropdown title="Transactions" lists={LISTTWO} /> 
      </div> 

      <div className={styles.testContainer}>
        <p className={styles.slideText}>Slide Table Left Or Right To View More</p>
        <div className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <div className={styles.checkBox}><div className={styles.boxBlue}></div></div>
            <p className={styles.tableHeaderTextTwo}>Discount Code</p>
            <p className={styles.tableHeaderTextTwo}>Ref Number</p>
            <p className={styles.tableHeaderText}>Category</p>
            <p className={styles.tableHeaderText}>Discount</p>
            <p className={styles.dateText}>Date</p>
            <p className={styles.dateText}>Status</p>
          </div>

          <TableItem status="Expired" />

          <TableItem status="Pending" />
          <TableItem status="Used" />

          <TableItem status="Pending" />
          <TableItem status="Expired" />

          <TableItem status="Pending" />
          <TableItem status="Used" />

          <TableItem status="Expired" />
          <TableItem status="Expired" />

          <TableItem status="Used" />
          <TableItem status="Pending" />

          <TableItem status="Used" />
        </div>
      </div>

      <div style={{height: "100px", width: "10px"}}></div>

    </div>
  )
}

export default TransactionHistory;