import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import groupLogo from "../../assets/images/logoGroup.svg"
import logoSide from "../../assets/images/logoSide.svg"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from "./styles.module.css"

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [confimrPass, setConfirmPass] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [errorText, setErrorText] = useState('')

  const handlePassChange = (e) => {
    setPassword(e.target.value)
  }

  const handleConfirmPassChange = (e) => {
    setConfirmPass(e.target.value)
  }

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault()

    if (confimrPass === password) {
      // Password is them same
      navigate("/all-set")
    } else {
      setErrorText("Password are different!")
    }
  }

  const goHome = () => {
    navigate("/")
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={goHome}>
        <img src={groupLogo} alt="Logo" />
      </div>

      <div className={styles.containerTwo}>
        <div className={styles.sideLogoContainer}>
          <img height={600} src={logoSide} alt="Logo" />
        </div>

        <div className={styles.formContainer}>
          <form className={styles.formInner} onSubmit={handleSubmit}>
            <div className={styles.textContainer}>
              <p className={styles.textOne}>Reset Password</p>
            </div>

            <div className={styles.containerThree}>
              {
                errorText.length > 0 &&
                <p className={styles.errorText}>{errorText}</p>
              }
              <input 
                placeholder="Password"  
                onFocus={() => setErrorText("")}
                onChange={handlePassChange}
                value={password}
                className={styles.inputPassword}
                required
                type={showPassword ? "text" : "password"}
                style={{
                  border: errorText.length > 0 ?
                  "1px solid red": ""
                }}
              />
              {
                showPassword ? 
                  <VisibilityOffIcon className={styles.eyeIcon} onClick={() => setShowPassword(false)} /> : 
                  <VisibilityIcon className={styles.eyeIcon} onClick={() => setShowPassword(true)} /> 
              }
            </div>


            <div className={styles.containerThree}>
              <input 
                placeholder="Confirm Password"  
                onChange={handleConfirmPassChange}
                onFocus={() => setErrorText("")}
                value={confimrPass}
                className={styles.inputPassword}
                required
                type={showConfirmPassword ? "text" : "password"}
                style={{
                  border: errorText.length > 0 ?
                  "1px solid red": ""
                }}
              />
              {
                showConfirmPassword ? 
                  <VisibilityOffIcon className={styles.eyeIcon} onClick={() => setShowConfirmPassword(false)} /> : 
                  <VisibilityIcon className={styles.eyeIcon} onClick={() => setShowConfirmPassword(true)} /> 
              }
            </div>

            <button type="submit" className={styles.btn}>Submit</button>

          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;

