import React from "react"
import { useNavigate } from "react-router-dom"
import Image31 from "../../assets/images/Image31.svg"

import styles from "./styles.module.css"

const Performance = () => {
  const navigate = useNavigate()

  return (
    <section className={styles.container}>
      <div className={styles.contentOne}>
        <p className={styles.hero__section__text}>
          Performance Driven Marketing Solutions for all Businesses
        </p>
      </div>

      <div className={styles.contentTwo}>
        <p className={styles.smallText}>
          Turn prospects into loyal customers with performance-driven marketing solutions. 
          Leverage AI-powered technology to deliver shoppable content that sells. Optimize your 
          campaigns to meet your marketing goals and drive profitable growth. 
        </p>

        <div className={styles.contentThree}>
          <div className={styles.tickIcontext}>
            <img className={styles.tickIcon} src={Image31} alt="tik icon" />
            <p className={styles.smallText}>76 million high-intent shoppers</p>
          </div>
          <div className={styles.tickIcontext}>
            <img className={styles.tickIcon} src={Image31} alt="tik icon" />
            <p className={styles.smallText}>Up to 75% increase in revenue</p>
          </div>
          <div className={styles.tickIcontext}>
            <img className={styles.tickIcon} src={Image31} alt="tik icon" />
            <p className={styles.smallText}>Up to 50% in return customers</p>
          </div>
        </div>

        <div onClick={() => navigate("/business-form")} className={styles.waitList_btn}>
          Sign Up My Business
        </div>
      </div>
    </section>
  )
}

export default Performance