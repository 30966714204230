import React, {useEffect, useRef} from "react"
import mobileCancel from "../../assets/images/mobileCancel.svg"
import styles from "./styles.module.css"


const JoinNow = ({ openJoinNow, setOpenJoinNow}) => {

  const closeDrawer = () => setOpenJoinNow(false);

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeDrawer(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);  


  const handleContentClick = () => {
  }

  return (
    <div className={openJoinNow ? styles.drawerOpen : styles.drawer}>
      <div ref={ref} className={styles.drawerContent} onClick={handleContentClick}>
        <p className={styles.textOne}>Join Now</p>
        <img onClick={closeDrawer} className={styles.cancel} src={mobileCancel} alt="Cancel Icon" />
        <div>
          <p className={styles.textTwo}>
            Connecting you to the most rewarding places to shop and spend with up to 25% in discounts only on Postpaid. 
          </p>

          <p className={styles.textThree}>For N8,250 a year, you get the following 👇 </p>

          <div className={styles.listContainer}>
            <p className={styles.textFour}>All year-round discount access</p>
            <p className={styles.textFour}>Unlimited discount category access</p>
            <p className={styles.textFour}>Daily discount code renewal</p>
            <p className={styles.textFour}>Access to Limited Time Offers</p>

          </div>

          <p className={styles.textFive}>To start enjoying, make payments to 👇 </p>

          <a className={styles.link} href="https://checkout.nomba.com/payment-link/8397314630" target="_blank" rel="noopener noreferrer">
            Make payments here!
          </a>

          <div className={styles.containerText}>
            <p className={styles.textSeven}>Bank Transfer:</p>
            <p style={{color: "blue"}} className={styles.textSeven}>7520432702</p>
            <p className={styles.textSeven}>Amucha MFB</p>
            <p className={styles.textSeven}>OATS Technologies Africa</p>
          </div>

          <p className={styles.textSix}>
            Join now to start enjoying your membership benefits
          </p>


          <a className={styles.link} href="https://chat.whatsapp.com/CkczgeIVhoUEWbRaquPOwi" target="_blank" rel="noopener noreferrer">
            Join WhatsApp Group!
          </a>

        </div>

      </div>
    </div>
  )
}

export default JoinNow