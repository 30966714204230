import React from "react"
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css"

const AllSet = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.container}>

      <div className={styles.card}>
        <p className={styles.textOne}>You’re All Set</p>

        <p className={styles.textTwo}>Go back to login and use your new details</p>

        <button onClick={() => navigate('/login')} className={styles.btn}>Login</button>
      </div>
    </div>
  )
}

export default AllSet;