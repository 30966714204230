import React, { useState } from 'react';
import styles from "./style.module.css"
import Image37 from "../../assets/images/Image37.svg"

const DragAndDrop = ({setCompanyLogo, showInputError, companyLogo}) => {
  const [dragging, setDragging] = useState(false);

  const [preview, setPreview] = useState(null)

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleError = () => {
    if (showInputError && !companyLogo) {
      return true;
    }
    return false
  }

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = e.dataTransfer.files;
    setCompanyLogo(files[0])
    setPreview(URL.createObjectURL(files[0]))
  };

  const handleFileChange = (event) => {
    let uploadFile = event.target.files[0]
    setCompanyLogo(uploadFile)
    setPreview(URL.createObjectURL(uploadFile))
  }

  return (
    <div
      className={`${ handleError() ? styles.errorInput : styles.dropzone} ${dragging ? styles.dragging : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input type="file" onChange={handleFileChange} className={styles.uploadInput} />
      {/* <img src={Image37} alt='upload' /> */}

      {
        preview ? <img src={preview} alt='company logo' className={styles.companyLogo} /> 
        : <img src={Image37} alt='upload' className={styles.companyLogoTwo} />
      }

      {
        handleError() && <p className={styles.errorText}>Please Upload Company Logo!</p>
      }

      {
        companyLogo && <p className={styles.fileText}>{companyLogo.name}</p>
      }

    </div>
  );
};

export default DragAndDrop;
