import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context";
import styles from "./styles.module.css"

const Categories = () => {

  const { pageNav, setPageNav } = useContext(AppContext)

  return (
    <div className={styles.container}>
      <p className={pageNav === "alloffers" ? styles.textOneActive : styles.textOne} onClick={() => setPageNav("alloffers")}>All Offers</p>

      <p className={pageNav === "offers" ? styles.textOneActive : styles.textOne} onClick={() => setPageNav("offers")}>Limited Time Offers</p>

      <p className={pageNav === "gift" ? styles.textOneActive : styles.textOne} onClick={() => setPageNav("gift")}>Gifting</p>

      <p className={pageNav === "holiday" ? styles.textOneActive : styles.textOne} onClick={() => setPageNav("holiday")}>Holiday Special</p>
      <p className={pageNav === "health" ? styles.textOneActive : styles.textOne} onClick={() => setPageNav("health")}>Healthcare</p>
      <p className={pageNav === "help" ? styles.textOneActive : styles.textOne} onClick={() => setPageNav("help")}>Help</p>
    </div>
  )
}

export default Categories;