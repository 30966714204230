import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import groupLogo from "../../assets/images/logoGroup.svg"
import MoreInfo from "../../components/SignupInfo/MoreInfo";
import InfoOne from "../../components/SignupInfo/InfoOne";
import styles from "./styles.module.css"

const Signup = () => {

  const [step, setSteps] = useState(0);

  const renderSteps = () => {
    switch(step) {
      case 0: 
        return <InfoOne setSteps={setSteps} />;
      case 1: 
        return <MoreInfo setSteps={setSteps} />;
      default:
        return <InfoOne />;    
    }
  }

  const navigate = useNavigate()

  const goHome = () => {
    navigate("/")
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={goHome}>
        <img src={groupLogo} alt="Logo" />
      </div>
      {renderSteps()}
    </div>
  )
}

export default Signup;

