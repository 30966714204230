import React, {useEffect, useRef, useState} from "react"

import styles from "./styles.module.css"
import DropDown from "../DropdownTwo/DropDown";

const LISTITEMS = ["A", "B", "C"]

const ShopNow = ({ openShopNow, setOpenShopNow}) => {

  const closeDrawer = () => setOpenShopNow(false);

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [orgName, setOrgName] = useState('')
  const [orgType, setOrgType] = useState('')

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value)
  }

  const handlePhoneChange = (e) => {
    setPhone(e.target.value)
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeDrawer(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);  


  const handleContentClick = () => {
  }

  return (
    <div className={openShopNow ? styles.drawerOpen : styles.drawer}>
      <div ref={ref} className={styles.drawerContent} onClick={handleContentClick}>
        <p className={styles.textOne}>Ready to Give a Gift?</p>

        <div className={styles.containerFour}>
          <input 
            placeholder="First Name"  
            onChange={handleFirstNameChange}
            value={firstName}
            className={styles.inputTwo}
          />

          <input 
            placeholder="Last Name"  
            onChange={handleLastNameChange}
            value={lastName}
            className={styles.inputTwo}
          />
        </div>

        <input 
          placeholder="Email Address"  
          onChange={handleEmailChange}
          value={email}
          className={styles.input}
          type="email"            
        />

        <input 
          placeholder="Phone Number"  
          onChange={handlePhoneChange}
          value={phone}
          className={styles.input}
          type="tel"
        />

        <input 
          placeholder="Organisation Name"  
          onChange={handleOrgNameChange}
          value={orgName}
          className={styles.input}
        />

        <div className={styles.dropDownItem}>
          <DropDown
            label="Organisation Type"
            data={LISTITEMS.sort()}
            value={orgType}
            setValue={setOrgType}
          />
        </div>

        <button className={styles.btn} onClick={closeDrawer}>Send Gift</button>
      </div>
    </div>
  )
}

export default ShopNow