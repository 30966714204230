import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { AppContext } from "../../context";
import SideDrawer from "../../components/Drawer/SideDrawer";

import styles from "./styles.module.css";
import androidMenu from "../../assets/images/androidMenu.svg";
import PostpaidLogo from "../../assets/images/postpaidLogo.svg";
import sliderIcon from "../../assets/images/sliderIcon.svg"
import SmileEmbassy from "../../assets/images/SmileEmbassy.png";
import Roperb from "../../assets/images/Roperb.jpeg";
import LuxeDental from "../../assets/images/LuxeDental.png";
import Optimist from "../../assets/images/Optimist.png";
import Yanga from "../../assets/images/Yanga.png";
import TeethMinder from "../../assets/images/TeethMinder.png";
import OneHealth from "../../assets/images/OneHealth.png";
import DrugMaster from "../../assets/images/DrugMaster.jpg";
import CareMore from "../../assets/images/CareMore.png";
import Savans from "../../assets/images/Savans.jpg";
import Farmit from "../../assets/images/Farmit.png";
import SeedSpike from "../../assets/images/SeedSpike.png";
import Supplya from "../../assets/images/Supplya.png";
import T40 from "../../assets/images/T40.png";
import Meridiem from "../../assets/images/Meridiem.png";
import CycleBreeze from "../../assets/images/CycleBreeze.png";
import Gamp from "../../assets/images/Gamp.png";
import Mandilas from "../../assets/images/Mandilas.png";
import Fixit from "../../assets/images/Fixit.png";
import Justrite from "../../assets/images/Justrite.jpeg";
import Kishbod from "../../assets/images/Kishbod.jpg";
import PRA from "../../assets/images/PRA.jpeg";
import Greenbaskit from "../../assets/images/Greenbaskit.jpeg";
import Mako from "../../assets/images/Mako.svg";


const AllOffers = () => {

    const [isOpen, setIsOpen] = useState(false);
    const { pageNav, setPageNav } = useContext(AppContext)

    const navigate = useNavigate()

    const goHome = () => {

    }

    return (
        <div>
            {/* <div className={styles.header}>
                <div className={styles.containerThree}>
                    <div className={styles.containerTwo} onClick={goHome}>
                        <img src={PostpaidLogo} alt="Company Pics" />
                        <p className={styles.textOne}>Postpaid</p>
                    </div>

                    <p className={styles.textTwo}>Who we are?</p>

                    <p className={styles.textTwo}>How to use</p>
                </div>

                <>
                    <img onClick={() => setIsOpen(true)} src={androidMenu} alt="android menu bar" className={styles.menuBar} />
                    <div className={styles.containerFour}>
                        <p className={styles.textThree}>Business</p>
                        <p className={styles.textThree}>Corporate</p>

                        <Divider style={{ height: "30px" }} orientation="vertical" variant="middle" flexItem />

                        <button className={styles.btnLogin} onClick={() => navigate('/login')}>Login</button>

                        <button className={styles.btnLoginOutlined} onClick={() => navigate('/signup')}>Sign Up</button>
                    </div>

                </>
            </div> */}

            <div className={styles.alloffers__container}>
                <p className={styles.alloffers__text}>Dental</p>

                <div className={styles.pharmacy__container}>
                    <div className={styles.pharmacy__content}>
                        <img src={SmileEmbassy} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>25% off</p>
                        </div>

                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={Roperb} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>3% - 10% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={LuxeDental} className={styles.pharmacy__img_ii} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>3% - 10% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={Optimist} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>25% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={Yanga} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>10% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={TeethMinder} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>5% -10% off</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.alloffers__pack}>
                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Consultations</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={OneHealth} className={styles.pharmacy__img} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>10% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Farm to Table</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={Farmit} className={styles.pharmacy__img} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>10% off</p>
                            </div>
                        </div>

                        <div className={styles.pharmacy__content_i}>
                            <img src={SeedSpike} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>10% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Autorepairs</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={Fixit} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>5% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Supermarkets</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={Justrite} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>7% off</p>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div className={styles.alloffers__container}>
                <p className={styles.alloffers__text_i}>Pharmacy</p>

                <div className={styles.pharmacy__container}>
                    <div className={styles.pharmacy__content}>
                        <img src={DrugMaster} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>10% off</p>
                        </div>

                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={CareMore} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>10% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={OneHealth} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>10% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={Savans} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>10% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={Kishbod} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>10% off</p>
                        </div>
                    </div>

                    <div className={styles.pharmacy__content}>
                        <img src={Mako} className={styles.pharmacy__img} alt="pharmacy__images" />
                        <div className={styles.pharmacy__context}>
                            <p className={styles.pharmacy__context__text}>10% off</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.alloffers__pack}>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Packaged Foods</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={Supplya} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>15% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Transportation</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={T40} className={styles.pharmacy__img} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>2.5% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Eyecare</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={Meridiem} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>25% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Business Support</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={CycleBreeze} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>20% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Gadgets & Consumer Electronics</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={Gamp} className={styles.pharmacy__img} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>2% off</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className={styles.alloffers__pack}>
                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Wholefoods</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={Greenbaskit} className={styles.pharmacy__img_i} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>7.5% off</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.alloffers__container_i}>
                    <p className={styles.alloffers__text}>Insurance</p>

                    <div className={styles.pharmacy__container_i}>
                        <div className={styles.pharmacy__content}>
                            <img src={PRA} className={styles.pharmacy__img} alt="pharmacy__images" />
                            <div className={styles.pharmacy__context}>
                                <p className={styles.pharmacy__context__text}>20% off</p>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
}

export default AllOffers;
