import React from "react";
import cardInfo from "../../assets/images/customerSer.svg"
import styles from "./styles.module.css"

const CustomerService = () => {

  return (
    <div className={styles.container}>
      <h1 className={styles.headertext}>Customer Service</h1>

      <div className={styles.contentMain}>
        <img src={cardInfo} alt="Card Icon" className={styles.img} />

        <p className={styles.textOne}>We’re getting things ready</p>
      </div>
    </div>
  )
}

export default CustomerService;