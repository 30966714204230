import React from "react";
import replaceCard from "../../assets/images/replaceCard.svg"
import styles from "./styles.module.css"


const Membership = () => {
  return (
    <div className={styles.container}>
      <div className={styles.itemOne}>
        <p className={styles.textOne}>
          Maximize your Membership!<br />

          Get amazing rewards!!<br />

          All year-round discounts on offer!!!
        </p>
      </div>

      <div className={styles.itemTwo}>
        <img src={replaceCard} alt="Card" className={styles.card} />
      </div>
    </div>
  )
}

export default Membership;