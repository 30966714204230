import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import SideDrawer from "../../components/Drawer/SideDrawer";
import androidMenu from "../../assets/images/androidMenu.svg";
import PostpaidLogo from "../../assets/images/postpaidLogo.svg";
import styles from "./styles.module.css";
import SmileEmbassy from "../../assets/images/SmileEmbassy.png";
import Roperb from "../../assets/images/Roperb.jpeg";
import LuxeDental from "../../assets/images/LuxeDental.png";
import Optimist from "../../assets/images/Optimist.png";
import Yanga from "../../assets/images/Yanga.png";
import TeethMinder from "../../assets/images/TeethMinder.png";
import OneHealth from "../../assets/images/OneHealth.png";
import DrugMaster from "../../assets/images/DrugMaster.jpg";
import CareMore from "../../assets/images/CareMore.png";
import Kishbod from "../../assets/images/Kishbod.jpg";
import Savans from "../../assets/images/Savans.jpg";
import Cerba from "../../assets/images/Cerba.png";
import Meridiem from "../../assets/images/Meridiem.png";
import PRA from "../../assets/images/PRA.jpeg";
import Mako from "../../assets/images/Mako.svg";

const HealthySavings = () => {

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate()

  const goHome = () => {
    navigate("/")
  }


  return (
    <>
      {/* <div className={styles.header}>
        <div className={styles.containerThree}>
          <div className={styles.containerTwo} onClick={goHome}>
            <img src={PostpaidLogo} alt="Company Pics" />
            <p className={styles.textOne}>Postpaid</p>
          </div>

          <p className={styles.textTwo}>Who we are?</p>

          <p className={styles.textTwo}>How to use</p>
        </div>

        <>
          <img onClick={() => setIsOpen(true)} src={androidMenu} alt="android menu bar" className={styles.menuBar} />
          <div className={styles.containerFour}>
            <p className={styles.textThree}>Business</p>
            <p className={styles.textThree}>Corporate</p>

            <Divider style={{ height: "30px" }} orientation="vertical" variant="middle" flexItem />

            <button className={styles.btnLogin} onClick={() => navigate('/login')}>Login</button>

            <button className={styles.btnLoginOutlined} onClick={() => navigate('/signup')}>Sign Up</button>
          </div>
        </>
      </div> */}

      <div className={styles.alloffers__container}>
        <p className={styles.alloffers__text}>Dental</p>

        <div className={styles.pharmacy__container}>
          <div className={styles.pharmacy__content}>
            <img src={SmileEmbassy} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>25% off</p>
            </div>

          </div>

          <div className={styles.pharmacy__content}>
            <img src={Roperb} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>3% - 10% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={LuxeDental} className={styles.pharmacy__img_ii} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>3% - 10% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={Optimist} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>25% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={Yanga} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>10% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={TeethMinder} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text_i}>5% - 30% off</p>
            </div>
          </div>

        </div>
      </div>
      <div className={styles.alloffers__container}>
        <p className={styles.alloffers__text}>Pharmacy</p>

        <div className={styles.pharmacy__container}>
          <div className={styles.pharmacy__content}>
            <img src={DrugMaster} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>10% off</p>
            </div>

          </div>

          <div className={styles.pharmacy__content}>
            <img src={CareMore} className={styles.pharmacy__img_i} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>10% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={OneHealth} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>10% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={Savans} className={styles.pharmacy__img_i} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>10% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={Kishbod} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>10% off</p>
            </div>
          </div>

          <div className={styles.pharmacy__content}>
            <img src={Mako} className={styles.pharmacy__img} alt="pharmacy__images" />
            <div className={styles.pharmacy__context}>
              <p className={styles.pharmacy__context__text}>10% off</p>
            </div>
          </div>

        </div>
      </div>
      <div className={styles.alloffers__pack}>
        <div className={styles.alloffers__container_i}>
          <p className={styles.alloffers__text}>Consultations</p>

          <div className={styles.pharmacy__container_i}>
            <div className={styles.pharmacy__content}>
              <img src={OneHealth} className={styles.pharmacy__img} alt="pharmacy__images" />
              <div className={styles.pharmacy__context}>
                <p className={styles.pharmacy__context__text}>10% off</p>
              </div>
            </div>

          </div>
        </div>
        <div className={styles.alloffers__container_i}>
          <p className={styles.alloffers__text}>Lab/Diagnostics</p>

          <div className={styles.pharmacy__container_i}>
            <div className={styles.pharmacy__content}>
              <img src={Cerba} className={styles.pharmacy__img} alt="pharmacy__images" />
              <div className={styles.pharmacy__context}>
                <p className={styles.pharmacy__context__text}>25% off</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.alloffers__container_i}>
          <p className={styles.alloffers__text}>Eyecare</p>

          <div className={styles.pharmacy__container_i}>
            <div className={styles.pharmacy__content}>
              <img src={Meridiem} className={styles.pharmacy__img} alt="pharmacy__images" />
              <div className={styles.pharmacy__context}>
                <p className={styles.pharmacy__context__text}>24% off</p>
              </div>
            </div>

          </div>
        </div>

        <div className={styles.alloffers__container_i}>
          <p className={styles.alloffers__text}>Health Insurance</p>

          <div className={styles.pharmacy__container_i}>
            <div className={styles.pharmacy__content}>
              <img src={PRA} className={styles.pharmacy__img} alt="pharmacy__images" />
              <div className={styles.pharmacy__context}>
                <p className={styles.pharmacy__context__text}>10% off</p>
              </div>
            </div>

          </div>
        </div>
        <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>



    </>
  )
}

export default HealthySavings;