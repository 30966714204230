import React from "react"
import Image31 from "../../assets/images/Image31.svg"
import Image36 from "../../assets/images/Image162.svg"
import styles from "./styles.module.css"

const SimpleEasy = () => {
  return (
    <section className={styles.container}>

      <div className={styles.partOne}>
        <div className={styles.content}>
          <p className={styles.hero__section__text}>Simple and Easy Integration </p>

          <p className={styles.smallText}>
            Discover how it works with your existing e-commerce platform and POS Service Providers - 
            and give your customers more shopping flexibility. 
          </p>

          <div>
            <p className={styles.smallText}>Our partnership network boasts:</p>
            <div className={styles.contentThree}>
              <div className={styles.tickIcontext}>
                <img className={styles.tickIcon} src={Image31} alt="tik icon" />
                <p className={styles.smallText}>More integration options than most other discount service providers.</p>
              </div>
              <div className={styles.tickIcontext}>
                <img className={styles.tickIcon} src={Image31} alt="tik icon" />
                <p className={styles.smallText}>A range of ways to integrate, from low-effort solutions to full integrations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.partTwo}>
        <div className={styles.partTwoInner}>
          <img src={Image36} className={styles.image36} />
        </div>
      </div>  
    </section>
  )
}

export default SimpleEasy