import React, {useContext} from "react"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../../context"
import checkedBlack from '../../assets/images/checkedBlack.svg'
import styles from './styles.module.css'

const UpgradeCard = () => {
  const {setSubScribe} = useContext(AppContext)

  const navigate = useNavigate()

  const handleRegister = () => {
    setSubScribe(true)
    navigate("/dashboard")
  }
  return (
    <div className={styles.container}>
      <p className={styles.textOne}>Regular</p>

      <p className={styles.textTwo}>
        Get connected to the most rewarding places to shop with up to 50% in 
        discounts and rewards only on Postpaid .
      </p>

      <div className={styles.containerTwo}>
        <p className={styles.textThree}>₦16,500</p>
        <p className={styles.textFour}>Per member, Per Year</p>
        <p className={styles.textTwo}>
          Apply Discount Code at checkout for 50% off valid till 31st December 2024.
        </p>
      </div>

      <div className={styles.containerThree}>
        <div className={styles.pointsContainer}>
          <img src={checkedBlack} />
          <p className={styles.textFive}>All year-round discount access</p>
        </div>
        <div className={styles.pointsContainer}>
          <img src={checkedBlack} />
          <p className={styles.textFive}>Unlimited Discount Category Access</p>
        </div>
        <div className={styles.pointsContainer}>
          <img src={checkedBlack} />
          <p className={styles.textFive}>Daily Discount Codes Renewal </p>
          {/* <p className={styles.textSix}>Coming Soon</p> */}
        </div>
        <div className={styles.pointsContainer}>
          <img src={checkedBlack} />
          <p className={styles.textFive}>Access to Limited Time Offers</p>
          {/* <p className={styles.textSix}>Coming Soon</p> */}
        </div>
      </div>

      <div className={styles.btnContainer}>
        <button className={styles.btn} onClick={handleRegister}>Register</button>
      </div>
    </div>
  )
}

export default UpgradeCard