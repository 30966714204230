import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./styles.module.css";
import verified from "../../../../../assets/images/verified.svg";
import Account from "../../../../../assets/images/Account.svg";
import Manage from "../../../../../assets/images/Manage.svg";
import SecurityandPrivacy from "../../../../../assets/images/SecurityandPrivacy.svg";
import sidebarSettings from "../../../../../assets/images/sidebarSettings.svg";
import sidebarLogout from "../../../../../assets/images/sidebarLogout.svg";

const Sidebar = () => {

    const navigate = useNavigate();
    const [active, setActive] = useState('active');

    const handleNavigation = (path) => {
        setActive(path); // Set the active path
        navigate(path); // Navigate to the path
    }

    return (
        <div className={styles.businesssidebar__container}>
            <p className={styles.businesssidebar__text}>Luxe Dental</p>

            <div className={styles.businesssidebar__context}>
                <p className={styles.businesssidebar__verify}>Verified Partner</p>
                <img src={verified} className={styles.businesssidebar__verify} alt="verify__icon" />
            </div>

            <div onClick={() => setActive('active')} className={`${styles.businesssidebar__content} ${active === '/account' ? styles.active : ''}`}>
                <img src={Account} className={styles.businesssidebar__account} alt="sidebar__icon" />
                <p className={styles.businesssidebar__account__text}>Account Information</p>
            </div>

            <div onClick={() => setActive('active')} className={`${styles.businesssidebar__content} ${active === '/business-manage' ? styles.active : ''}`}>
                <img src={Manage} className={styles.businesssidebar__account} alt="sidebar__icon" />
                <p className={styles.businesssidebar__account__text}>Manage Discounts</p>
            </div>

            <div onClick={() => handleNavigation('/security')} className={`${styles.businesssidebar__content} ${active === '/security' ? styles.active : ''}`}>
                <img src={SecurityandPrivacy} className={styles.businesssidebar__account} alt="sidebar__icon" />
                <p className={styles.businesssidebar__account__text}>Security and Privacy</p>
            </div>

            <div className={styles.businesssidebar__section}>
                <div onClick={() => handleNavigation('/settings')} className={`${styles.businesssidebar__content} ${active === '/settings' ? styles.active : ''}`}>
                    <img src={sidebarSettings} className={styles.businesssidebar__account} alt="sidebar__icon" />
                    <p className={styles.businesssidebar__account__text}>Customer Service</p>
                </div>

                <div className={styles.businesssidebar__content}>
                    <img src={sidebarLogout} className={styles.businesssidebar__account} alt="sidebar__icon" />
                    <p className={styles.businesssidebar__account__text_ii}>Logout</p>
                </div>
            </div>

        </div>
    );
}

export default Sidebar;