import React, {useState, useContext} from "react";
import PostpaidLogo from "../../assets/images/postpaidLogo.svg"
import androidMenu from "../../assets/images/androidMenu.svg"
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BusinessProfit from "../../components/BusinessProfit/BusinessProfit";
import BusinessCategory from "../../components/BusinessCategory/BusinessCategory";
import CardinalPromises from "../../components/CardinalPromises/CardinalPromises";
import WorldOfConsumers from "../../components/WorldOfConsumers/WorldOfConsumers";
import Performance from "../../components/PeformanceDriven/Performance";
import BusinessAccordion from "../../components/BusinessAccordion/BusinessAccordion";
import RetryUI from "../../components/RetryUI/RetryUI";
import SimpleEasy from "../../components/SimpleEasy/SimpleEasy";
// import FAQsPage from "../../components/FAQsPage/FAQsPage";
import FAQsPageBus from "../../components/FAQsPage/FAQsPageBus";
// import Footer from "../../components/Footer/Footer";
import FooterBus from "../../components/FooterBus/FooterBus";
import SideDrawer from "../../components/Drawer/SideDrawer";
import { AppContext } from "../../context";
import styles from "./styles.module.css"

const BusinessPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {pageNav, setPageNav} = useContext(AppContext)


  const navigate = useNavigate()

  const goHome = () => {
    navigate("/")
    setPageNav("")
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.containerThree}>
            <div className={styles.containerTwo} onClick={goHome}>
              <img src={PostpaidLogo} alt="Company Pics" />
              <p className={styles.textOne}>Postpaid</p>
            </div>

            {/* <p className={styles.textTwo}>Who we are?</p>

            <p className={styles.textTwo}>How to use</p> */}
          </div>

          <>
            <img onClick={() => setIsOpen(true)} src={androidMenu} alt="android menu bar" className={styles.menuBar} />
            <div className={styles.containerFour}>
            <p className={styles.textThree} onClick={goHome}>Consumer</p>
            <p className={styles.textThree} onClick={() => navigate("/business")}>Business</p>

              <Divider style={{height: "30px"}} orientation="vertical" variant="middle" flexItem />

              {/* <button className={styles.btnLogin}>Join Now</button> */}

              {/* <button className={styles.btnLogin} onClick={() => navigate('/login')}>Login</button> */}

              {/* <button className={styles.btnLoginOutlined} onClick={() => navigate('/signup')}>Sign Up</button> */}
            </div>
          </>
        </div>

        <BusinessProfit />

        <CardinalPromises />

        <BusinessCategory />

        <WorldOfConsumers />

        <Performance />

        <BusinessAccordion />

        <RetryUI />

        <SimpleEasy />

        <FAQsPageBus />

        <FooterBus />
      </div>
      <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}

export default BusinessPage