import React from 'react';
import Chart from 'react-apexcharts';


class MonthlyChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: 'Net Profit',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 76, 75, 72, 74]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded',
                        columnWidth: '30%',
                        columnbackgroundColor: 'red',
                        borderRadius: 8,
                    },
                },
                // dataLabels: {
                //     enabled: false
                // },

                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                },

                fill: {
                    opacity: 1,
                    // colors: ['blue'],

                },
                // tooltip: {
                //     y: {
                //         formatter: function (val) {
                //             return "$ " + val + " thousands"
                //         }
                //     }
                // }
            },


        };
    }



    render() {
        return (
            <div>
                <div id="chart">
                    <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
                </div>
                <div id="html-dist"></div>
            </div>
        );
    }
}

export default MonthlyChart;