import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./test.css"

const faqs = [
  {
    question: "What is Postpaid?",
    answer:
      "We connect consumers with exclusive discounts, promotions, and rewards, while helping businesses drive growth, increase customer loyalty, and reach new heights.",
  },
  {
    question: "How do I register for Postpaid?",
    answer:
      "You can register here to gain access to our exclusive discount offers",
  },
  {
    question: "How much does Postpaid registration cost?",
    answer:
      "Annual membership to Postpaid costs N16,500. Avail yourself of our 50% off registration fee this season",
  },
  {
    question: "How does Postpaid work?",
    answer:
      "*Join Postpaid for a membership fee *Spend at partner location and earn Discounts *Enjoy your rewards instantly",
  },
  {
    question: "How do I use Postpaid?",
    answer:
      "*Membership Identification *Discount Code Verification *Checkout with Discount Earned",
  },
];

const FAQsPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate()

  return (
    <div className="faqs-page">
      <div className="faqs-container">
        <h1 className="faqs-header">FAQs</h1>
        <div
          className={`faq-item ${activeIndex === 0 ? "active" : ""}`}
          key={0}
          onClick={() => toggleFAQ(0)}
        >
          <div className="faq-question">
            {faqs[0].question}
            <span className="faq-arrow">
              {activeIndex === 0 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">{faqs[0].answer}</div>
        </div>

        <div
          className={`faq-item ${activeIndex === 1 ? "active" : ""}`}
          key={1}
          onClick={() => toggleFAQ(1)}
        >
          <div className="faq-question">
            {faqs[1].question}
            <span className="faq-arrow">
              {activeIndex === 1 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">
            <p>
              You can register <span onClick={() => navigate("/signup")} style={{color: "red", cursor: "pointer"}} >here</span> to gain access to our exclusive discount offers
            </p>
          </div>
        </div>

        <div
          className={`faq-item ${activeIndex === 2 ? "active" : ""}`}
          key={2}
          onClick={() => toggleFAQ(2)}
        >
          <div className="faq-question">
            {faqs[2].question}
            <span className="faq-arrow">
              {activeIndex === 2 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">{faqs[2].answer}</div>
        </div>

        <div
          className={`faq-item ${activeIndex === 3 ? "active" : ""}`}
          key={3}
          onClick={() => toggleFAQ(3)}
        >
          <div className="faq-question">
            {faqs[3].question}
            <span className="faq-arrow">
              {activeIndex === 3 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">{faqs[3].answer}</div>
        </div>

        <div
          className={`faq-item ${activeIndex === 4 ? "active" : ""}`}
          key={4}
          onClick={() => toggleFAQ(4)}
        >
          <div className="faq-question">
            {faqs[4].question}
            <span className="faq-arrow">
              {activeIndex === 4 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">{faqs[4].answer}</div>
        </div>
      </div>
    </div>
  );
};

export default FAQsPage;
