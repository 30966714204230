import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EvStationIcon from '@mui/icons-material/EvStation';
import MedicationIcon from '@mui/icons-material/Medication';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AppleIcon from '@mui/icons-material/Apple';
import groupLogo from "../../assets/images/logoGroup.svg"
import styles from "./styles.module.css"

const ItemCategory = ({ CatIcon, title, id, addItem, removeItem, widthParam}) => {
  const [selected, setSelected] = useState(false)

  const handleClick = () => {
    if (selected) {
      removeItem(id)
      setSelected(false)
    } else {
      addItem(id)
      setSelected(true)
    }
  }

  return (
    <div 
      style={{width: widthParam, backgroundColor: selected ? "#183887" : "white" }} 
      className={styles.itemContainer} 
      onClick={handleClick}
    >
      {CatIcon}
      <p className={styles.textOne} style={{color: selected ? "white" : "#183887" }}>{title}</p>
    </div>
  )
}

const SelectCategory = () => {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([])

  const removeItem = (IDParam) => {
    let tempList = selectedItems.filter((el) => el !== IDParam);
    setSelectedItems(tempList)
  }

  const addItem = (IDParam) => {
    let tempList = [...selectedItems, IDParam]
    setSelectedItems(tempList)
  }

  const findItem = (IDParam) => selectedItems.includes(IDParam)

  const goHome = () => {
    navigate("/")
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={goHome}>
        <img src={groupLogo} alt="Logo" />
      </div>

      <p className={styles.itemText}>Select up to 5 discount Categories to begin</p>

      <div className={styles.mainContent}>

        <div className={styles.containerTwo}>
          <ItemCategory 
            widthParam="200px" 
            CatIcon={<EvStationIcon style={{color: findItem(0) ? "white" : "#183887"}} />} 
            addItem={addItem} id={0} 
            removeItem={removeItem} 
            title="Fuel & Gas"
          />
          <ItemCategory 
            widthParam="200px" 
            CatIcon={<MedicationIcon style={{color: findItem(1) ? "white" : "#183887"}} />} 
            addItem={addItem} id={1} 
            removeItem={removeItem} 
            title="Pharmacy"
          />
          <ItemCategory 
            widthParam="200px" 
            CatIcon={<FitnessCenterIcon style={{color: findItem(2) ? "white" : "#183887"}} />} 
            addItem={addItem} id={2} 
            removeItem={removeItem} 
            title="Fitness"
          />
          <ItemCategory 
            widthParam="200px" 
            CatIcon={<EvStationIcon style={{color: findItem(3) ? "white" : "#183887"}} />} 
            addItem={addItem} id={3} 
            removeItem={removeItem} 
            title="Gadgets"
          />
        </div>

        <div className={styles.containerTwo}>
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(4) ? "white" : "#183887"}} />} 
            addItem={addItem} id={4} 
            removeItem={removeItem} 
            title="Dental Care"
          />
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(5) ? "white" : "#183887"}} />} 
            addItem={addItem} id={5} 
            removeItem={removeItem} 
            title="Entertainment"
          />
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(6) ? "white" : "#183887"}} />} 
            addItem={addItem} id={6} 
            removeItem={removeItem} 
            title="Toys & Games"
          />
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<AppleIcon style={{color: findItem(7) ? "white" : "#183887"}} />} 
            addItem={addItem} id={7} 
            removeItem={removeItem} 
            title="Wholesale Foods"
          />
        </div>

        <div className={styles.containerTwo}>
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(8) ? "white" : "#183887"}} />} 
            addItem={addItem} id={8} 
            removeItem={removeItem} 
            title="Eye Care"
          />
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(9) ? "white" : "#183887"}} />} 
            addItem={addItem} id={9} 
            removeItem={removeItem} 
            title="Health Care"
          />
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(10) ? "white" : "#183887"}} />} 
            addItem={addItem} id={10} 
            removeItem={removeItem} 
            title="Apparel"
          />
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(11) ? "white" : "#183887"}} />} 
            addItem={addItem} id={11} 
            removeItem={removeItem} 
            title="Groceries"
          />
          <ItemCategory 
            widthParam="220px" 
            CatIcon={<EvStationIcon style={{color: findItem(12) ? "white" : "#183887"}} />} 
            addItem={addItem} id={12} 
            removeItem={removeItem} 
            title="Restaurants"
          />
        </div>

      </div>

      <div className={styles.btnContainer}>
        <button className={styles.btn} onClick={() => navigate("/dashboard")}>Go to Postpaid</button>
      </div>

    </div>
  )
}

export default SelectCategory