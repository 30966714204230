import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBVj2MUSuepGlHgnknE-JxUCjqs70bptNo",
  authDomain: "postpaid-bbe31.firebaseapp.com",
  databaseURL: "https://postpaid-bbe31-default-rtdb.firebaseio.com",
  projectId: "postpaid-bbe31",
  storageBucket: "postpaid-bbe31.appspot.com",
  messagingSenderId: "50728916395",
  appId: "1:50728916395:web:d55df907d88b97264f6eee",
  measurementId: "G-T406B84HGP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);


export { app, db, auth, analytics, storage };