import React, {useState} from "react"
import Image36 from "../../assets/images/Image36.svg"

import styles from "./styles.module.css"


const BusinessAccordion = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const listItems = [
    {
      title: "Give Your Business the Postpaid Advantage",
      desc: "Your customers stay with you, new ones are attracted to your business simply because you offer them the best deals for their spending."
    },
    {
      title: "Shopping On Their Own Terms",
      desc:
        "Experience increased spending in your business as consumers tend to spend more when they realize they earn discounts when they spend",
    },
    {
      title: "Discounts Drive Conversion",
      desc:
        "With discounts and benefits on offer, your customer base grows exponentially.",
    },
    {
      title: "Checkout Made Easy",
      desc:
        "Our proprietary technology allows for seamless online and offline checkout. Allowing you to validate membership, offer the requisite category discount and ensures that your bookkeeping is not messy.",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.partOne}>
        <div className={styles.content}>
          {
            listItems.map((el, index) => (
            <div 
              key={index}
              className={`${styles.contentItem} ${activeIndex === index ? styles.active : ""}`}
              onClick={() => toggleFAQ(index)}>
              <p className={styles.hero__section__text}>{el.title}</p>
  
              <p className={styles.smallText}>
                {el.desc}
              </p>
            </div>
            ))
          }

        </div>
      </div>

      <div className={styles.partTwo}>
        <div className={styles.partTwoInner}>
          <img src={Image36} className={styles.image36} />
        </div>
      </div>    
    </div>
  )
}

export default BusinessAccordion;