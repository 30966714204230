// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styles from "./styles.module.css";
// import filterIcon from '../../../../../assets/images/filter.svg';
// import transac from '../../../../../assets/images/transact.svg';

// const VerifyCodes = () => {
//     const [businesses, setBusinesses] = useState([]);
//     const [selectedBusinessId, setSelectedBusinessId] = useState("");
//     const [verifiedCodes, setVerifiedCodes] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState("");

//     useEffect(() => {
//         fetchBusinesses();
//     }, []);

//     useEffect(() => {
//         if (selectedBusinessId) {
//             fetchVerifiedCodes(selectedBusinessId);
//         }
//     }, [selectedBusinessId]);

//     // Fetch all businesses for dropdown
//     const fetchBusinesses = async () => {
//         setError("");
//         try {
//             const response = await axios.get("https://us-central1-postpaid-bbe31.cloudfunctions.net/app/api/business/businesses");
//             setBusinesses(response.data); // Set the list of businesses
//         } catch (err) {
//             setError("Failed to fetch businesses.");
//             console.error("Error fetching businesses:", err);
//         }
//     };

//     // Fetch verified discount codes by businessId
//     const fetchVerifiedCodes = async (businessId) => {
//         setLoading(true);
//         setError("");

//         try {
//             const response = await axios.get(`https://us-central1-postpaid-bbe31.cloudfunctions.net/app/api/discounts/verified?businessId=${businessId}`);
//             setVerifiedCodes(response.data); // Set the filtered discount codes
//         } catch (err) {
//             setError("Failed to fetch verified codes.");
//             console.error("Error fetching verified codes:", err);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div>
//             <div className={styles.container}>
//                 <div className={styles.containerTwo}>
//                     <p className={styles.titleText}>Verified Discounts</p>
//                     <img src={filterIcon} className={styles.filter} alt="filter icon" />
//                 </div>

//                 <div className={styles.dropdownContainer}>
//                     <label htmlFor="businessSelect" className={styles.dropdownLabel}>
//                         Select Business:
//                     </label>
//                     <select
//                         id="businessSelect"
//                         className={styles.dropdown}
//                         value={selectedBusinessId}
//                         onChange={(e) => setSelectedBusinessId(e.target.value)}
//                     >
//                         <option value="">-- Select a Business --</option>
//                         {businesses.map((business) => (
//                             <option key={business.businessId} value={business.businessId}>
//                                 {business.businessName}
//                             </option>
//                         ))}
//                     </select>
//                 </div>

//                 {loading && <p className={styles.loading}>Loading...</p>}
//                 {error && <p className={styles.error}>{error}</p>}

//                 <ul className={styles.containerThree}>
//                     {verifiedCodes.length > 0 ? (
//                         verifiedCodes.map((code) => (
//                             <li key={code.code} className={styles.test}>
//                                 <div className={styles.containerFour}>
//                                     <div className={styles.hightlight}>
//                                         <img src={transac} alt="Transaction Icon" />
//                                     </div>

//                                     <div>
//                                         <p className={styles.titleTextTwo}>{code.code}</p>
//                                         <p className={styles.textTwo}>{code.category}</p>
//                                     </div>
//                                 </div>

//                                 <p className={styles.percent}>
//                                     {code.discountPercentage ? `${code.discountPercentage}%` : "N/A"}
//                                 </p>
//                             </li>
//                         ))
//                     ) : (
//                         <p className={styles.noCodes}>No verified discounts available</p>
//                     )}
//                 </ul>

//                 <p className={styles.code}>View All Codes</p>
//             </div>
//         </div>
//     );
// };

// export default VerifyCodes;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from "./styles.module.css";
import filterIcon from '../../../../../assets/images/filter.svg';
import transac from '../../../../../assets/images/transact.svg';

const VerifyCodes = () => {
    const [verifiedCodes, setVerifiedCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchVerifiedCodes();
    }, []);

    const fetchVerifiedCodes = async () => {
        setLoading(true);
        setError("");

        try {
            const response = await axios.get("https://us-central1-postpaid-bbe31.cloudfunctions.net/app/api/discounts/verified");
            setVerifiedCodes(response.data); // Set the verified discount codes
        } catch (err) {
            setError("Failed to fetch verified codes.");
            console.error("Error fetching verified codes:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.containerTwo}>
                    <p className={styles.titleText}>Verified Discounts</p>
                    <img src={filterIcon} className={styles.filter} alt="filter icon" />
                </div>

                {loading && <p className={styles.loading}>Loading...</p>}
                {error && <p className={styles.error}>{error}</p>}

                <ul className={styles.containerThree}>
                    {verifiedCodes.length > 0 ? (
                        verifiedCodes.map((code) => (
                            <li key={code.code} className={styles.test}>
                                <div className={styles.containerFour}>
                                    <div className={styles.hightlight}>
                                        <img src={transac} alt="Transaction Icon" />
                                    </div>

                                    <div>
                                        <p className={styles.titleTextTwo}>{code.code}</p>
                                        <p className={styles.textTwo}>{code.category}</p>
                                    </div>
                                </div>

                                <p className={styles.percent}>
                                    {code.discountPercentage ? `${code.discountPercentage}%` : "N/A"}
                                </p>
                            </li>
                        ))
                    ) : (
                        <p className={styles.noCodes}>No verified discounts available</p>
                    )}
                </ul>

                <p className={styles.code}>View All Codes</p>
            </div>
        </div>
    );
};

export default VerifyCodes;