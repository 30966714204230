import React from 'react'
import transac from '../../assets/images/checked.svg'
import styles from "./styles.module.css"

const TransactHisCard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containerTwo}>
        <p className={styles.titleText}>Transaction History</p>
      </div>

      <ul className={styles.containerThree}>
        <li className={styles.test}>
          <div className={styles.containerFour}>
            <div className={styles.hightlight}>
              <img src={transac}  alt='Transac Icon' />
            </div>

            <div>
              <p className={styles.titleTextTwo}>Payment from Xabi Alonso</p>
              <p className={styles.textTwo}>3 hours ago</p>
            </div>
          </div>

          <div className={styles.containerFive}>
            <p className={styles.money}>&#8358; 47,500</p>
            <p className={styles.success}>Success</p>
          </div>

        </li>
        <li className={styles.test}>
          <div className={styles.containerFour}>
            <div className={styles.hightlight}>
              <img src={transac}  alt='Transac Icon' />
            </div>

            <div>
              <p className={styles.titleTextTwo}>Payment from Xabi Alonso</p>
              <p className={styles.textTwo}>3 hours ago</p>
            </div>
          </div>

          <div className={styles.containerFive}>
            <p className={styles.money}>&#8358; 47,500</p>
            <p className={styles.success}>Success</p>
          </div>

        </li>
        <li className={styles.test}>
          <div className={styles.containerFour}>
            <div className={styles.hightlight}>
              <img src={transac}  alt='Transac Icon' />
            </div>

            <div>
              <p className={styles.titleTextTwo}>Payment from Xabi Alonso</p>
              <p className={styles.textTwo}>3 hours ago</p>
            </div>
          </div>

          <div className={styles.containerFive}>
            <p className={styles.money}>&#8358; 47,500</p>
            <p className={styles.success}>Success</p>
          </div>

        </li>
        <li className={styles.test}>
          <div className={styles.containerFour}>
            <div className={styles.hightlight}>
              <img src={transac}  alt='Transac Icon' />
            </div>

            <div>
              <p className={styles.titleTextTwo}>Payment from Xabi Alonso</p>
              <p className={styles.textTwo}>3 hours ago</p>
            </div>
          </div>

          <div className={styles.containerFive}>
            <p className={styles.money}>&#8358; 47,500</p>
            <p className={styles.success}>Success</p>
          </div>

        </li>
        <li className={styles.test}>
          <div className={styles.containerFour}>
            <div className={styles.hightlight}>
              <img src={transac}  alt='Transac Icon' />
            </div>

            <div>
              <p className={styles.titleTextTwo}>Payment from Xabi Alonso</p>
              <p className={styles.textTwo}>3 hours ago</p>
            </div>
          </div>

          <div className={styles.containerFive}>
            <p className={styles.money}>&#8358; 47,500</p>
            <p className={styles.success}>Success</p>
          </div>

        </li>
      </ul>

      <p className={styles.code}>View All Codes</p>
    </div>
  )
}

export default TransactHisCard;