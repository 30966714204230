import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.css"
import logout from "../../assets/images/logout.svg"

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import Modal from "../Modal/Modal";
import { AppContext } from "../../context";

const Sidebar = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [openModal, setOpenModal] = useState(false)

  const {subscribed} =  useContext(AppContext)

  const goToPage = (url) => {
    navigate(url);
  }

  const checkPath = (testParams) => {
    if (location.pathname === testParams) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className={styles.container}>
        <p className={styles.textprofile}>Blaise Matuidi</p>
        <p className={styles.idText}>User ID 6328155DVB33680</p>

        <div className={styles.textContainer}>
          <p onClick={() => goToPage('/upgrade-plan')} className={styles.upgradetext}>Membership Plan <span className={styles.span}>Subscription</span></p>
          <p className={styles.date}>Next Payment date is 15th June</p>
        </div>

        {
          subscribed &&
          <div className={styles.navContainer}>
            <div className={styles.navItem} onClick={() => goToPage('/dashboard')}>
              <AccountCircleOutlinedIcon style={{ color: checkPath('/') ? "#3873FF" : "#000"}} />
              <p className={checkPath('/dashboard') ? styles.navtextActive : styles.navtext}>Account Information</p>
            </div>
            <div className={styles.navItem} onClick={() => goToPage('/dashboard/cards')}>
              <CreditCardOutlinedIcon style={{ color: checkPath('/dashboard/cards') ? "#3873FF" : "#000"}} />
              <p className={checkPath('/dashboard/cards') ? styles.navtextActive : styles.navtext}>Cards</p>
            </div>
            <div className={styles.navItem} onClick={() => goToPage('/dashboard/discount-offers')}>
              <WysiwygIcon style={{ color: checkPath('/dashboard/discount-offers') ? "#3873FF" : "#000"}} />
              <p className={checkPath('/dashboard/discount-offers') ? styles.navtextActive : styles.navtext}>Discount Offers</p>
            </div>
            <div className={styles.navItem} onClick={() => goToPage('/dashboard/transaction-history')}>
              <LocalOfferOutlinedIcon style={{ color: checkPath('/dashboard/transaction-history') ? "#3873FF" : "#000"}} />
              <p className={checkPath('/dashboard/transaction-history') ? styles.navtextActive : styles.navtext}>Transaction History</p>
            </div>
            <div className={styles.navItem} onClick={() => goToPage('/dashboard/privacy')}>
              <GppGoodOutlinedIcon style={{ color: checkPath('/dashboard/privacy') ? "#3873FF" : "#000"}} />
              <p className={checkPath('/dashboard/privacy') ? styles.navtextActive : styles.navtext}>Security and Privacy</p>
            </div>

          </div>
        }

        <div className={styles.navContainerTwo}>
          {
            subscribed && 
            <div className={styles.navItem} onClick={() => goToPage('/dashboard/customer-service')}>
              <SettingsOutlinedIcon style={{ color: checkPath('/dashboard/customer-service') ? "#3873FF" : "#000"}} />
              <p className={checkPath('/dashboard/customer-service') ? styles.navtextActive : styles.navtext}>Customer Service</p>
            </div>
          }

          <div className={styles.navItem} onClick={() => setOpenModal(true)}>
            <img src={logout} alt="User icon" />
            <p className={styles.logoutText}>Log-Out</p>
          </div>
        </div>
      </div>
      {
        openModal && <Modal setOpenModal={setOpenModal} />
      }
    </>
  )
}

export default Sidebar;