import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./styles.module.css";
import Dropdown from "../Dropdown/Dropdown";

const CATEGORIES = ["food", "transport", "shopping", "entertainment"];

const TableItem = ({ status, code, category, createdAt, expirationDate }) => {
  const [checked, setChecked] = useState(false);

  const renderStatus = () => {
    switch (status) {
      case "Active":
        return <p className={styles.pendingText}>Active</p>;
      case "Used":
        return <p className={styles.usedText}>Used</p>;
      case "Expired":
        return <p className={styles.expiredText}>Expired</p>;
      default:
        return <p>Unknown</p>;
    }
  };

  return (
    <div className={`${styles.tableContent} ${status === 'Used' ? styles.usedTableRow : ''}`}>
      <div className={styles.checkBoxTwo} onClick={() => setChecked((prev) => !prev)}>
        {checked ? <div className={styles.boxBlue}></div> : <></>}
      </div>
      <p className={styles.contentTextTwo}>{code}</p>
      <p className={styles.contentText}>{category}</p>
      <p className={styles.contextDate}>Created At: {new Date(createdAt).toLocaleString()}</p>
      <p className={styles.contextDate}>Expires: {new Date(expirationDate).toLocaleString()}</p>
      <div className={styles.contextDate}>{renderStatus()}</div>
    </div>
  );
};

const DiscountOffers = () => {
  const [activeCodes, setActiveCodes] = useState({});
  const [discountHistory, setDiscountHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  // Fetch userId from localStorage
  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      try {
        const userData = JSON.parse(storedUserData);
        setUserId(userData.uid || null);
      } catch (error) {
        console.error("Error parsing user data:", error);
        setUserId(null);
      }
    }
  }, []);

  const fetchActiveCodes = async () => {
    if (!userId) {
      console.error("No user ID available");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("userIdToken");
      const response = await axios.get(`https://us-central1-postpaid-bbe31.cloudfunctions.net/app/api/discounts/active/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setActiveCodes(response.data);
    } catch (error) {
      console.error("Error fetching active codes:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDiscountHistory = async () => {
    if (!userId) return;

    try {
      const token = localStorage.getItem("userIdToken");
      const response = await axios.get(`https://us-central1-postpaid-bbe31.cloudfunctions.net/app/api/discounts/history/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const formattedHistory = response.data.map((item) => ({
        ...item,
        createdAt: item.createdAt ? new Date(item.createdAt) : null,
        expirationDate: item.expirationDate ? new Date(item.expirationDate) : null,
      }));

      setDiscountHistory(formattedHistory);
    } catch (error) {
      console.error("Error fetching discount history:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchActiveCodes();
      fetchDiscountHistory();
      const interval = setInterval(fetchActiveCodes, 60000); // Refresh every minute
      return () => clearInterval(interval);
    }
  }, [userId]);

  if (loading && !userId) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.headertext}>Discount Offers</h1>

      <div className={styles.containerTwo}>
        <Dropdown title="Category" lists={CATEGORIES} />
        <Dropdown title="Discount Type" lists={CATEGORIES} />
      </div>

      <h3 className={styles.textOne}>Discount History</h3>
      <div className={styles.testContainer}>
        <p className={styles.slideText}>Slide Table Left Or Right To View More</p>
        <div className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <div className={styles.checkBox}><div className={styles.boxBlue}></div></div>
            <p className={styles.tableHeaderTextTwo}>Discount Code</p>
            <p className={styles.tableHeaderTextTwo}>Category</p>
            <p className={styles.dateText}>Created At</p>
            <p className={styles.dateText}>Expiration Date</p>
            <p className={styles.dateText}>Status</p>
          </div>

          {discountHistory.length > 0 ? (
            discountHistory.map((item, index) => (
              <TableItem
                key={index}
                status={item.status || "Unknown"}
                code={item.code || "N/A"}
                category={item.category || "N/A"}
                createdAt={item.createdAt}
                expirationDate={item.expirationDate}
              />
            ))
          ) : (
            <div className={styles.noHistory}>No discount history found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscountOffers;
