import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./test.css"

const faqs = [
  {
    question: "What type of businesses does Postpaid partner with?",
    answer:
      "Postpaid works with thousands of different businesses across various industries, sectors and categories.",
  },
  {
    question: "Can the discounts be negotiated?",
    answer:
      "Discounts are set by category to be uniform for easier and simpler marketing. Statutory category discounts are non-negotiable downwards but you can offer more through a special sales/promotion campaign. Contact us if you wish to run a special sales/promotion campaign.",
  },
  {
    question: "Is there an order size where discount starts to kick in?",
    answer:
      "Our discounts are designed to kick in from purchases of N5,000 and above. However, if you sell small ticket sized items, we can kick in discounts starting from N2,500.",
  },
  {
    question: "How does integration work?",
    answer:
      "You can check our savings calculator here to learn more about what you save across various shopping categories.",
  },
];

const FAQsPageBus = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate()

  return (
    <div className="faqs-page">
      <div className="faqs-container">
        <h1 className="faqs-header">FAQs</h1>
        <div
          className={`faq-item ${activeIndex === 0 ? "active" : ""}`}
          key={0}
          onClick={() => toggleFAQ(0)}
        >
          <div className="faq-question">
            {faqs[0].question}
            <span className="faq-arrow">
              {activeIndex === 0 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">{faqs[0].answer}</div>
        </div>

        <div
          className={`faq-item ${activeIndex === 1 ? "active" : ""}`}
          key={1}
          onClick={() => toggleFAQ(1)}
        >
          <div className="faq-question">
            {faqs[1].question}
            <span className="faq-arrow">
              {activeIndex === 1 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">{faqs[1].answer}</div>
        </div>

        <div
          className={`faq-item ${activeIndex === 2 ? "active" : ""}`}
          key={2}
          onClick={() => toggleFAQ(2)}
        >
          <div className="faq-question">
            {faqs[2].question}
            <span className="faq-arrow">
              {activeIndex === 2 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">{faqs[2].answer}</div>
        </div>

        <div
          className={`faq-item ${activeIndex === 3 ? "active" : ""}`}
          key={3}
          onClick={() => toggleFAQ(3)}
        >
          <div className="faq-question">
            {faqs[3].question}
            <span className="faq-arrow">
              {activeIndex === 3 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
          <div className="faq-answer">
            Whether you’re on an e-commerce platform or you use our direct API implementation, 
            the integration process is designed to be quick and easy. <br />
            Once we decide to partner together, we'll give you access to our learning management 
            platform, which will walk you through the integration step by step. Most of our partners 
            integrate very quickly, and our Integrations Team is always available if you need help. <br />
            Once the integration is complete, your customers won’t ever have to leave you.   
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQsPageBus;
