import React from "react";
import styles from "./styles.module.css"

const Settings = () => {

  return (
    <div className={styles.container}>
      <h1>Settings</h1>
    </div>
  )
}

export default Settings;