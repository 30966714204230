import React from "react"
import PostpaidLogo from "../../assets/images/postpaidLogo.svg"
import Layer_x from "../../assets/images/Layer_x.svg"
import styles from "./styles.module.css"


const Footer = () => {
  return (
    <>
      <footer className={styles.container}>
        <div className={styles.containerIcon}>
          <div className={styles.containerTwo}>
            <img src={PostpaidLogo} alt="Company Pics" />
            <p className={styles.textOne}>Postpaid</p>
          </div>
          <img src={Layer_x} height={40} width={100} alt="Layer_x Pics" />
        </div>

        <div className={styles.textContainer}>
          <p className={styles.textTitle}>About</p>

          <p className={styles.smallText}>Getting Started</p>
          <p className={styles.smallText}>About Us</p>
          <p className={styles.smallText}>Advertising & Partnership</p>
          <p className={styles.smallText}>Influencers & Partners</p>
          <p className={styles.smallText}>Press Room</p>
          <p className={styles.smallText}>Careers</p>
          <p className={styles.smallText}>Blog</p>
          <p className={styles.smallText}>Terms & Condition</p>
          <p className={styles.smallText}>Privacy</p>
          <p className={styles.smallText}>Personal Data Opt-out</p>
          <p className={styles.smallText}>Help</p>
        </div>

        <div className={styles.textContainer2}>
          <p className={styles.textTitle}>Partner Sites</p>

          <p className={styles.smallText}>OATS Technologies Africa</p>
          <p className={styles.smallText}>Alpha & Jam</p>
        </div>

        <div className={styles.textContainer3}>
          <p className={styles.textTitle}>Seasonal Pages</p>

          <p className={styles.smallText}>Gifting</p>
          <p className={styles.smallText}>Black Friday</p>
          <p className={styles.smallText}>Cyber Monday</p>
          <p className={styles.smallText}>Life Time Offers</p>
          <p className={styles.smallText}>Holiday Specials</p>
        </div>

        <div className={styles.textContainer4}>
          <p className={styles.textTitle}>Tools & Services</p>

          <p className={styles.smallText}>Online Discount</p>
          <p className={styles.smallText}>In-Store Dsicount</p>
          <p className={styles.smallText}>Postpaid Browser Extension</p>
          <p className={styles.smallTextTwo}>*Available for Google Chrome</p>
        </div>

        <div className={styles.circleContainer}>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
        </div>
      </footer>

      <div className={styles.containerThree}>

        <div className={styles.textContainerTwo}>
          <p className={styles.textTwo}>Advertising Disclosure</p>
        
          <p className={styles.textTwo2}>copyright 2024 Postpaid</p>
        </div>

        <div className={styles.circleContainerTwo}>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
        </div>
      </div>

    </>

  )
}

export default Footer;