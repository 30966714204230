import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import Modal from "../../../../Modal/Modal";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [openModal, setOpenModal] = useState(false)

    const goToPage = (url) => {
        navigate(url);
    }

    const isActive = (path) => location.pathname === path;

    return (
        <div>
            <div className={styles.verifysidebar__container}>
                {/* <p className={styles.verifysidebar__text}>Luxe Dental</p> */}

                <div className={`${styles.verifysidebar__content} ${isActive('/business-verify') ? styles.active : ''}`}
                    onClick={() => goToPage('/business-verify')}>
                    <AccountCircleOutlinedIcon style={{ color: isActive('/business-verify') ? "#3873FF" : "#000" }} />
                    {/* <img src={Account} className={styles.verifysidebar__account} alt="sidebar__icon" /> */}
                    <p style={{ color: isActive('/business-verify') ? "#3873FF" : "#000", paddingLeft: "1em", fontFamily: "Museo Slab", fontSize: "18px" }}>
                        Account Information
                    </p>
                </div>

                <div className={styles.verifysidebar__section}>
                    {/* <div className={`${styles.verifysidebar__content} ${isActive('/customer-service') ? styles.active : ''}`}
                        onClick={() => goToPage('/customer-service')}>
                        <img src={sidebarSettings} className={styles.verifysidebar__account} alt="sidebar__icon" />
                        <p className={isActive('/customer-service') ? styles.verifysidebar__text_active : styles.verifysidebar__account__text}>
                            Customer Service
                        </p>
                    </div> */}

                    {/* <div className={styles.verifysidebar__content} onClick={() => setOpenModal(true)}>
                        <img src={sidebarLogout} className={styles.verifysidebar__account} alt="sidebar__icon" />
                        <p className={styles.verifysidebar__account__text_ii}>Logout</p>
                    </div> */}
                </div>
            </div>
            {
                openModal && <Modal setOpenModal={setOpenModal} />
            }
        </div>
    );
}

export default Sidebar;
