import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "../Firebase";
import pics from "../../assets/images/AgwaraPhoto.jpg";
import camera from "../../assets/images/camera.svg";
import lock from "../../assets/images/lock.svg";
import styles from "./styles.module.css";

const Privacy = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = await getAuthToken(); // Function to retrieve Firebase auth token
        const response = await axios.get("/api/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { firstName, lastName, birthday } = response.data;
        setFirstName(firstName || "");
        setLastName(lastName || "");
        setBirthday(birthday || "");
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch user info.");
        console.error(err);
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handleSaveChanges = async () => {
    try {
      const token = await getAuthToken(); // Function to retrieve Firebase auth token
      await axios.put(
        "/api/users",
        { firstName, lastName, birthday },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessMessage("User info updated successfully!");
      setError("");
    } catch (err) {
      setError("Failed to update user info.");
      console.error(err);
    }
  };

  const getAuthToken = async () => {
    const auth = getAuth(app);
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          resolve(await user.getIdToken());
        } else {
          reject(new Error("User not authenticated"));
        }
      });
    });
  };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p className={styles.errorText}>{error}</p>;

  return (
    <div className={styles.container}>
      <h1 className={styles.headertext}>Security and Privacy</h1>

      <div className={styles.formContainer}>
        <div className={styles.containerTwo}>
          <h3 className={styles.infoText}>Personal Info</h3>
          <button className={styles.btn} onClick={handleSaveChanges}>
            Save Changes
          </button>
        </div>

        <div className={styles.profileContainer}>
          <img className={styles.profileImage} alt="Profile Pics" src={pics} />
          <div className={styles.halfCircle}>
            <img src={camera} alt="Camera Pics" />
          </div>
        </div>

        {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
        {error && <p className={styles.errorText}>{error}</p>}

        <div className={styles.formContainerInner}>
          <div className={styles.itemOne}>
            <label htmlFor="first name" className={styles.label}>
              First Name
            </label>
            <input
              name="first name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.itemOne}>
            <label htmlFor="last name" className={styles.label}>
              Last Name
            </label>
            <input
              name="last name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.itemOne}>
            <label htmlFor="birthday" className={styles.label}>
              Birthday
            </label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.itemTest}>
            <div className={styles.block}>
              <img src={lock} alt="Pad Lock" />
            </div>
            <p className={styles.text}>
              We keep data private and never share it with third-parties.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.formContainerTwo}>
        <h3 className={styles.infoText}>Access & Security</h3>

        <div className={styles.containerThree}>
          <div className={styles.itemTwo}>
            <p className={styles.textBold}>Sign-in Email</p>
            <p className={styles.mailText}>amaranngwani@gmail.com</p>
          </div>

          <div className={styles.itemTwo}>
            <p className={styles.textBold}>Password</p>
            <p className={styles.passwordText}>Change password</p>
          </div>

          <div className={styles.borderSolid}></div>

          <div className={styles.itemTwo}>
            <p className={styles.textBold}>2 Factor Authentication</p>
            <p className={styles.textLight}>Coming soon</p>
          </div>

          <div className={styles.itemTwo}>
            <p className={styles.textBold}>Phone Number</p>
            <p className={styles.textLight}>+234 902 123 673 3344</p>
          </div>
        </div>

      </div>

      <div style={{height: '40px'}}></div>
    </div>
  );
};

export default Privacy;
