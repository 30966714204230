import React from "react"
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css"

const VerifyEmail = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.container}>

      <div className={styles.card}>
        <p className={styles.textOne}>Verify Your Email</p>

        <p className={styles.textTwo}>An Email has been sent to you to verify your email address</p>

        <button onClick={() => navigate('/dashboard')} className={styles.btn}>I have verified my Email</button>
      </div>
    </div>
  )
}

export default VerifyEmail;