// ActiveDis Component
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import filterIcon from '../../assets/images/filter.svg';
import transac from '../../assets/images/transact.svg';
import styles from "./styles.module.css";

const ActiveDis = () => {
  const [activeCodes, setActiveCodes] = useState({});

  useEffect(() => {
    fetchActiveCodes();
  }, []);

  const fetchActiveCodes = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem('userData')).uid;
      const token = localStorage.getItem("userIdToken");
      const response = await axios.get(`https://us-central1-postpaid-bbe31.cloudfunctions.net/app/api/discounts/active/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setActiveCodes(response.data);
    } catch (error) {
      console.error("Error fetching active codes:", error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerTwo}>
        <p className={styles.titleText}>Active Discounts</p>
        <img src={filterIcon} className={styles.filter} alt='filter icon' />
      </div>

      <ul className={styles.containerThree}>
        {Object.entries(activeCodes).map(([category, codeData]) => (
          <li 
            key={category} 
            className={`${styles.test} ${codeData.status === 'Used' ? styles.usedCode : ''}`}
          >
            <div className={styles.containerFour}>
              <div className={styles.hightlight}>
                <img src={transac} alt='Transac Icon' />
              </div>
              <div>
                <p className={styles.titleTextTwo}>{codeData.code}</p>
                <p className={styles.textTwo}>{category}</p>
              </div>
            </div>
            <p className={styles.percent}>
              {codeData.discountPercentage ? `${codeData.discountPercentage}%` : 'N/A'}
            </p>
          </li>
        ))}
      </ul>

      <p className={styles.code}>View All Codes</p>
    </div>
  );
};

export default ActiveDis;