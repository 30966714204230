import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Home from "../../components/Home/Home";
import TransacHistory from "../../components/TransacHistory/TransacHistory";
import DiscountOffers from "../../components/DiscountOffers/DiscountOffers";
import Settings from "../../components/Settings/Settings";
import Privacy from "../../components/Privacy/Privacy";
import CustomerService from "../../components/CustomerService/CustomerService";
import Cards from "../../components/Cards/Cards";
import Header from "../../components/Header/Header";
import { AppContext } from "../../context";
import styles from "./styles.module.css";

const Dashboard = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const {subscribed} = useContext(AppContext)

  useEffect(() => {
    if (!subscribed) {
      navigate("/dashboard")
    }
  }, [location.pathname])

  return (
    <>
      <Header />
      <div className={styles.container}>
        <Sidebar />
        
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="transaction-history" element={<TransacHistory />} />
          <Route path="cards" element={<Cards />} />
          <Route path="discount-offers" element={<DiscountOffers />} />
          <Route path="settings" element={<Settings />} />
          <Route path="customer-service" element={<CustomerService />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>     
      </div>

    </>
  )
}

export default Dashboard;

