import React from "react";
import { useNavigate } from "react-router-dom";
import DarkBlueSpiral from "../../assets/images/DarkBlueSpiral.svg";
import styles from "./styles.module.css"


const RetryUI = () => {
  const navigate = useNavigate()

  return (
    <section className={styles.container}>
       <img src={DarkBlueSpiral} className={styles.spiral__img} />
      <p className={styles.hero__section__text}>Ready to Try the Discount Service Solution that Delivers?</p>

      <div className={styles.waitList_btn}>
        Sign Up My Business
      </div>
    </section>
  )
}

export default RetryUI