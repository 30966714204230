import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { getFirestore, doc, setDoc, getDocs, query, where, collection } from "firebase/firestore";
import signupLogo from "../../assets/images/signupLogo.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from "./styles.module.css";

const InfoOne = ({ setSteps }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('');
  const [checked, setChecked] = useState(false);
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

    // Function to generate a 6-digit random membership ID
    const generateMembershipId = () => {
      return Math.floor(100000 + Math.random() * 900000).toString();
    };
  
    // Function to check if a membership ID already exists
    const doesMembershipIdExist = async (membershipId) => {
      const pendingUsersQuery = query(collection(db, "pendingUsers"), where("membershipId", "==", membershipId));
      const usersQuery = query(collection(db, "users"), where("membershipId", "==", membershipId));
  
      const pendingUsersSnapshot = await getDocs(pendingUsersQuery);
      const usersSnapshot = await getDocs(usersQuery);
  
      return !pendingUsersSnapshot.empty || !usersSnapshot.empty;
    };
  
    // Generate a unique membership ID
    const generateUniqueMembershipId = async () => {
      let membershipId;
      let isUnique = false;
  
      while (!isUnique) {
        membershipId = generateMembershipId();
        isUnique = !(await doesMembershipIdExist(membershipId));
      }
  
      return membershipId;
    };

  const validateFields = () => {
    if (!firstName || !lastName || !email || !password || !phone) {
      setError("All fields are required.");
      return false;
    }
    if (!agree) {
      setError("You must agree to the Terms of Service and Privacy Policy.");
      return false;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return false;
    }
    return true;
  };

  const handleCreateAccount = async () => {
    if (!validateFields()) return;

    setError('');
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await sendEmailVerification(user);

       // Generate a unique membership ID
       const membershipId = await generateUniqueMembershipId();

      // Save user data to Firestore
      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        email,
        phone,
        rememberInfo: checked,
        membershipId,
        uid: user.uid,
        createdAt: new Date(),
      });

      alert("Account created successfully. Please verify your email.");
      setSteps(1);
    } catch (err) {
      console.error("Error creating account:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.containerTwo}>
      <div className={styles.sideLogoContainer}>
        <img height={600} src={signupLogo} alt="Logo" />
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formInner}>
          <div className={styles.textContainer}>
            <p className={styles.textOne}>Create your account</p>
            <p className={styles.textTwo}>Hello there! Let's create your account</p>
          </div>

          <div className={styles.containerFour}>
            <input 
              placeholder="First Name"  
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              className={styles.inputTwo}
            />

            <input 
              placeholder="Last Name"  
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              className={styles.inputTwo}
            />
          </div>

          <input 
            placeholder="Email Address"  
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className={styles.input}
            type="email"            
          />

          <input 
            placeholder="Phone Number XXX-XXXX-XXXX"  
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            className={styles.input}
            type="tel"
          />

          <div className={styles.containerThree}>
            <input 
              placeholder="Password" 
              onChange={(e) => setPassword(e.target.value)} 
              value={password} 
              className={styles.inputPassword} 
              type={showPassword ? "text" : "password"} 
            />
            {showPassword ? 
              <VisibilityOffIcon className={styles.eyeIcon} onClick={() => setShowPassword(false)} /> : 
              <VisibilityIcon className={styles.eyeIcon} onClick={() => setShowPassword(true)} />}
          </div>

          {error && <p className={styles.errorText}>{error}</p>}

          <div className={styles.remContainer}>
            <div className={styles.checkBoxTwo} onClick={() => setChecked(prev => !prev)}>
              {checked ? <div className={styles.boxBlue}></div> : null}
            </div>
            <p className={styles.textThree}>Remember Information</p>
          </div>

          <button className={styles.btn} onClick={handleCreateAccount} disabled={loading}>
            {loading ? "Creating Account..." : "Create Account"}
          </button>

          <div className={styles.remContainer}>
            <div className={styles.checkBoxTwo} onClick={() => setAgree(prev => !prev)}>
              {agree ? <div className={styles.boxBlue}></div> : null}
            </div>
            <p className={styles.textFour}>
              I agree to Postpaid &nbsp;
              <span className={styles.spanText}>Terms of Service</span> &nbsp; and &nbsp;
              <span className={styles.spanText}>Privacy Policy</span>
            </p>
          </div>

          <p className={styles.spanTextThree} onClick={() => navigate('/login')}>
            Other Sign-in Methods
          </p>
          <p className={styles.textFour} onClick={() => navigate('/login')}>
            Joined us before? <span className={styles.spanTextTwo}>Login</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoOne;