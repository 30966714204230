
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Dashboard from "../pages/Dashboard/Dashboard";
import BusinessPage from "../pages/BusinessPage/BusinessPage";
import Login from "../pages/Login/Login";
import NotFound from "../pages/NotFound/NotFound";
import UpgradePlan from "../pages/UpgradePlan/UpgradePlan";
import BusinessDashboard from "../components/Business/Dashboard/DashboardPage/BusinessDashboard/BusinessDashboard";
import Verify from "../components/Business/Dashboard/Verify/Verify";
import BusinessLogin from "../components/Business/Auth/BusinessLogin";
import BusinessSignup from "../components/Business/Auth/BusinessSignup";
import Signup from "../pages/Signup/Signup"
import UploadPhoto from "../pages/UploadPhoto/Upload";
import SelectCategory from "../pages/SelectCategory/SelectCategory";
import ForgotPassword from "../pages/ForgotPassword/Forgot";
import OTPPage from "../pages/OTPPage/OTPPgage";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import AllSet from "../pages/AllSet/AllSet";
import Landing from "../pages/Landing/Landing";
import VerifyEmail from "../pages/VerifyEmail/VerifyEmail";
import AllOffers from "../components/Categories/AllOffers";
import HealthySavings from "../components/HealthySavings/HealthySavings";
import BusinessFormPage from "../pages/BusinessFormPage/BusinessFormPage";
import Help from "../components/Help/Help";

import { AppContext } from "../context";

import "./App.css";

const App = () => {

  const [pageNav, setPageNav] = useState("")
  const [subscribed, setSubScribe] = useState(false)
  const [openJoinNow, setOpenJoinNow] = useState(false)

  return (

    <AppContext.Provider value={{ pageNav, subscribed, setPageNav, setSubScribe, openJoinNow, setOpenJoinNow }}>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/all-set" element={<AllSet />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/upload" element={<UploadPhoto />} />
          <Route path="/select-category" element={<SelectCategory />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/otp" element={<OTPPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/upgrade-plan" element={<UpgradePlan />} />
          <Route path="/categories" element={<AllOffers />} />
          <Route path="/healthcare" element={<HealthySavings />} />
          <Route path="/help" element={<Help />} />
          <Route path="/business" element={<BusinessPage />} />
          <Route path="/business-dashboard" element={<BusinessDashboard />} />
          <Route path="/business-verify" element={<Verify />} />
          <Route path="/business-login" element={<BusinessLogin />} />
          <Route path="/business-signup" element={<BusinessSignup />} />
          <Route path="/business-form" element={<BusinessFormPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  )
}

export default App;