import React, {useState} from "react";

import styles from "./styles.module.css";

const CATEGORIES = [
  { name: "Supermarket", discount: 10 },
  { name: "Pharmacy", discount: "5-10" },
  { name: "Gadgets", discount: 20 },
  { name: "Diagnostics", discount: 10 },
  { name: "Hospital", discount: 25 },
  { name: "Dental", discount: 25 },
  { name: "Eye Care", discount: 25 },
  { name: "Fitness", discount: 25 },
  { name: "Restaurant", discount: 10 },
  { name: "Fashion & Lifestyle", discount: 25 },
  { name: "Gas", discount: 10 },
  { name: "Filling Station", discount: 5 },
  { name: "Movies x Cinema", discount: 10 },
];

const DISCOUNTS = [
  2, 2.5, 3, 5, 7, 10, 15, 20, 25, 30 
]

const Calcuator = () => {
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [result, setResult] = useState(null);

  const handleCalculate = () => {
    if (!category || !amount || !discount) {
      setResult("Please fill all fields");
      return;
    }
    const discountValue = parseFloat(discount);
    const amountValue = parseFloat(amount);
    const savings = (amountValue * discountValue) / 100;
    setResult(`You save ₦${savings.toFixed(2)}`);
  };

  const handleClear = () => {
    setCategory("");
    setAmount("");
    setDiscount("");
    setResult(null);
  };

  

  return (
    <div className={styles.container}>
      <p className={styles.textOne}>Savings Calculator</p>
      <p className={styles.textTwo}>Stay on top of your budget</p>

      <form className={styles.formContainer} onSubmit={(e) => {
            e.preventDefault();
            handleCalculate();
          }}>
        <div className={styles.formGroup}>
          <label htmlFor="category" className={styles.label}>Select Shopping Category</label>
          <select
            id="category"
            value={category}
            className={styles.select}
            onChange={(e) => {
              const selectedCategory = CATEGORIES.find(
                (cat) => cat.name === e.target.value
              );
              setCategory(selectedCategory.name);
              setDiscount(selectedCategory.discount);
            }}
          >
            <option className={styles.option} value="">--Select Category--</option>
            {CATEGORIES.map((cat, index) => (
              <option className={styles.option} key={index} value={cat.name}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="amount">Select Amount Spent</label>
          <input
            type="number"
            id="amount"
            className={styles.input}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="discount" className={styles.label}>Discount</label>
          <select
            id="discount"
            value={discount}
            className={styles.select}
            onChange={(e) => {
              setDiscount(e.target.value);
            }}
          >
            <option className={styles.option} value="">--Select Discount--</option>
            {DISCOUNTS.map((cat, index) => (
              <option className={styles.option} key={index} value={cat}>
                {cat}%
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className={styles.calculateButton}>
          Apply
        </button>
      </form>

      {result && (
        <div className={styles.resultContainer}>
          <p className={styles.result}>{result}</p>
          <button className={styles.clearButton} onClick={handleClear}>
            Clear
          </button>
        </div>
      )}
    </div>
  )
}

export default Calcuator;