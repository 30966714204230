import React, {useState} from "react";
import PostpaidLogo from "../../assets/images/postpaidLogo.svg"
import androidMenu from "../../assets/images/androidMenu.svg"
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BusinessForm from "../../components/BusinessForm/BusinessForm";
import SideDrawer from "../../components/Drawer/SideDrawer";
import styles from "./styles.module.css"

const BusinessFormPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate()

  const goHome = () => {
    navigate("/")
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.containerThree}>
            <div className={styles.containerTwo} onClick={goHome}>
              <img src={PostpaidLogo} alt="Company Pics" />
              <p className={styles.textOne}>Postpaid</p>
            </div>

            {/* <p className={styles.textTwo}>Who we are?</p>

            <p className={styles.textTwo}>How to use</p> */}
          </div>

          <>
            <img onClick={() => setIsOpen(true)} src={androidMenu} alt="android menu bar" className={styles.menuBar} />
            <div className={styles.containerFour}>
            <p className={styles.textThree} onClick={() => navigate("/")}>Consumer</p>
            <p className={styles.textThree} onClick={() => navigate("/business")}>Business</p>

              <Divider style={{height: "30px"}} orientation="vertical" variant="middle" flexItem />

              {/* <button className={styles.btnLogin} onClick={() => navigate('/login')}>Login</button>

              <button className={styles.btnLoginOutlined} onClick={() => navigate('/signup')}>Sign Up</button> */}
            </div>
          </>
        </div>

        <BusinessForm />
      </div>


      <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}

export default BusinessFormPage