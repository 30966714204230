import React, { useState } from 'react';
import axios from 'axios';
import styles from "./styles.module.css";
import PostPaidIcon from "../../../assets/images/PostPaidIcon.svg";
import AuthLogo from "../../../assets/images/Auth.svg";

const BusinessSignup = () => {
    const [businessName, setBusinessName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleSignup = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage("");
        setError("");

        try {
            const response = await axios.post("/api/business/signup", {
                businessName,
                email,
                password,
                phoneNumber,
            });

            setMessage(response.data.message || "Signup successful!");
        } catch (err) {
            setError(err.response?.data?.message || "Signup failed. Please try again.");
            console.error("Signup error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className={styles.businesssignup__container}>
                <div className={styles.businesssignup__content}>
                    <div className={styles.businesssignup__firstcontent}>
                        <img src={AuthLogo} className={styles.auth__icon} alt="auth__icon" />
                    </div>

                    <div className={styles.businesssignup__secondcontent}>
                        <img src={PostPaidIcon} className={styles.postpaidlogo__icon} alt="postpaid__logo" />
                    </div>

                    <form onSubmit={handleSignup} className={styles.signup__form}>
                        <h2 className={styles.signup__title}>Business Signup</h2>

                        <div className={styles.form__group}>
                            <label htmlFor="businessName" className={styles.label}>Business Name</label>
                            <input
                                id="businessName"
                                type="text"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                className={styles.input}
                                required
                            />
                        </div>

                        <div className={styles.form__group}>
                            <label htmlFor="email" className={styles.label}>Email</label>
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={styles.input}
                                required
                            />
                        </div>

                        <div className={styles.form__group}>
                            <label htmlFor="password" className={styles.label}>Password</label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={styles.input}
                                required
                            />
                        </div>

                        <div className={styles.form__group}>
                            <label htmlFor="phoneNumber" className={styles.label}>Phone Number</label>
                            <input
                                id="phoneNumber"
                                type="tel"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className={styles.input}
                                required
                            />
                        </div>

                        <button type="submit" className={styles.signup__button} disabled={loading}>
                            {loading ? "Signing Up..." : "Sign Up"}
                        </button>
                    </form>

                    {message && <p className={styles.successMessage}>{message}</p>}
                    {error && <p className={styles.errorMessage}>{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default BusinessSignup;
