import React from "react";

import CategoriesGifting2 from "../../assets/images/CategoriesGifting2.svg"
import CategoriesGifting3 from "../../assets/images/CategoriesGifting3.svg"
import CategoriesGifting4 from "../../assets/images/CategoriesGifting4.svg"

import styles from "./styles.module.css";

const GiftingSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containerTwo}>
        <div className={styles.item1}>
          <img src={CategoriesGifting2} alt="card one" className={styles.imgOne} />
        </div>

        <div className={styles.item2}>
          <p className={styles.textOne}>
            Seeking to acquire and retain customers as a services business, 
            bank, microfinance bank or fintech?
          </p>
        </div>
      </div>

      <div className={styles.containerThree}>
        <div className={styles.item2}>
          <p className={styles.textOne}>
            Looking to improve the value of your group, association, or cooperative’s membership?
          </p>
        </div>

        <div className={styles.item1}>
          <img src={CategoriesGifting3} alt="card one" className={styles.imgOne} />
        </div>
      </div>

      <div className={styles.containerFour}>
        <div className={styles.item2}>
          <p className={styles.textOne}>
            Or just simply looking to reward staff for their work and contributions all year round?
          </p>
        </div>

        <div className={styles.item1}>
          <img src={CategoriesGifting4} alt="card one" className={styles.imgOne} />
        </div>
      </div>

      <p className={styles.textTwo}>
        Plug in to Postpaid Discounts as the ultimate reward for your customers, business partners, 
        associates, members, staff and family members this season with highly discounted bulk discount codes 
        across 5 – 8 categories depending on the plan you choose.
      </p>

      <button className={styles.btnLogin}>Share Gift</button>
    </div>
  )
}

export default GiftingSection;