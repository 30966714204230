import React from "react"

import styles from "./styles.module.css"

const DropDown = ({data, setValue, label, value}) => {
  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>

      <select className={styles.select} value={value} onChange={handleChange}>
        {data.map((element, key) => 
          <option value={element} key={`${element}${key}`}>{element}</option>
        )}
      </select>
    </div>
  )
}

export default DropDown