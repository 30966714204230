import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import groupLogo from "../../assets/images/logoGroup.svg";
import logoSide from "../../assets/images/logoSide.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoginOptions from "../../components/LoginOptions/LoginOptions";
import styles from "./styles.module.css";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePassChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Get additional user data from Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        
        // Store both ID token and user data in localStorage
        const idToken = await user.getIdToken();
        localStorage.setItem('userIdToken', idToken);
        localStorage.setItem('userData', JSON.stringify({
          uid: user.uid,
          email: user.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          username: userData.username
        }));

        navigate('/dashboard');
      } else {
        setError("User profile not found.");
        await auth.signOut(); // Sign out if no user document exists
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Invalid email or password. Please try again.");
    }
  };

  const goHome = () => {
    navigate("/")
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={goHome}>
        <img src={groupLogo} alt="Logo" />
      </div>
      <div className={styles.containerTwo}>
        <div className={styles.sideLogoContainer}>
          <img height={600} src={logoSide} alt="Logo" />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.formInner}>
            <div className={styles.textContainer}>
              <p className={styles.textOne}>Login</p>
              <p className={styles.textTwo}>Welcome back! Please enter your details</p>
            </div>
            {error && <p className={styles.errorText}>{error}</p>}
            <input
              placeholder="E-mail"  
              onChange={handleEmailChange}
              value={email}
              className={styles.input}
              onFocus={() => setError("")}
              type="email"
            />
            <div className={styles.containerThree}>
              <input
                placeholder="Password"  
                onChange={handlePassChange}
                onFocus={() => setError("")}
                value={password}
                className={styles.inputPassword}
                type={showPassword ? "text" : "password"}
              />
              {
                showPassword ?
                  <VisibilityOffIcon className={styles.eyeIcon} onClick={() => setShowPassword(false)} /> :
                  <VisibilityIcon className={styles.eyeIcon} onClick={() => setShowPassword(true)} />
              }
            </div>
            <div className={styles.remContainer}>
              <div className={styles.checkBoxTwo} onClick={() => setChecked(prev => !prev)}>
                {checked ? <div className={styles.boxBlue}></div> : null}
              </div>
              <p className={styles.textThree}>Remember Information</p>
            </div>
            <button className={styles.btn} onClick={handleLogin}>Login</button>
            <p className={styles.forgotPassword} onClick={() => navigate('/forgot-password')}>
              Forgot Password?
            </p>
            {/* <div className={styles.containerFour}>
              <LoginOptions />
            </div> */}
            <p className={styles.textFour} onClick={() => navigate('/signup')}>
              First time here? <span className={styles.spanText}>Sign up</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;