import React from "react";
import UpgradeCard from "../../components/UpgradeCard/UpgradeCard";
import UpgradeCardLight from "../../components/UpgradeCard/UpgradeCardLight";
import Header from "../../components/Header/Header";
import groupIcon from "../../assets/images/Group8888.svg"
import styles from "./styles.module.css";

const UpgradePlan = () => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <h1 className={styles.headerText}>Postpaid Membership <br />Registration</h1>

        <p className={styles.textOne}>Spend As Always, Save As Never Before</p>

        <div className={styles.cardContainer}>
          <UpgradeCard />
          {/* <UpgradeCardLight /> */}
        </div>

        <div className={styles.containerTwo}>
          <p className={styles.textTwo}>Payment Methods</p>

          <img className={styles.img} src={groupIcon} alt="payment options" />

          <p className={styles.textThree}>We accept Visa, American Express, Mastercard, Paypal and Crypto</p>
        </div>
      </div>
    </>
  )
}

export default UpgradePlan