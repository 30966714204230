import React from "react";

import styles from "./styles.module.css"

const WhatIsPostpaid = () => {
  return (
    <div className={styles.container}>
      <p className={styles.textOne}>What is Postpaid?</p>

      <div className={styles.containerTwo}>
        <div className={styles.cardOne}>
          <p className={styles.textTwo}>
            Postpaid: Your ultimate savings partner. Our innovative platform 
            connects you to exclusive discounts, promotions, and rewards, empowering you 
            to save more and live better
          </p>
        </div>
        <div className={styles.cardTwo}>
          <p className={styles.textTwo}>
            Join. Shop. Save.<br />
            Join today and find out why Postpaid membership is the real deal.
          </p>
        </div>
        <div className={styles.cardThree}>
          <p className={styles.textTwo}>
            We connect you to the most rewarding places to shop. Postpaid saves you money while you 
            shop the brands you love. Join today and find out why Postpaid members get it.
          </p>
        </div>
      </div>

      <div>
        <p className={styles.mobileText}>
          Postpaid is a revolutionary discount service that empowers consumers and businesses to thrive. 
        </p>
        <p className={styles.mobileText}>
          With an innovative, easy to use cutting-edge technology, we connect consumers with exclusive discounts, promotions, and rewards.
        </p>
        <p className={styles.mobileText}>
          Connect to the most rewarding places to shop. 
        </p>
        <p className={styles.mobileText}>
          Join today and find out why Postpaid members get it.
        </p>
      </div>
    </div>
  )
}

export default WhatIsPostpaid