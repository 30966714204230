import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LeftArrow from "../../assets/images/LeftArrow.svg";
import closeIcon from "../../assets/images/closeRed.svg";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import TextField from "../../components/Input/TextField/TextField";
import DropDown from "../../components/Input/DropDown/DropDown";
import TextArea from "../../components/Input/TextArea/TextArea";
import MultipleSelect from "../../components/Input/MultipleSelect/MultipleSelect";
import { stateAndLGA } from "../../data/stateAndLGA";
// import Footer from "../../components/Footer/Footer";
import FooterBus from "../FooterBus/FooterBus";
import errorIcon from "../../assets/images/errorIcon.svg";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { db, storage } from "../Firebase"; 
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import styles from "./styles.module.css";

const stateList = stateAndLGA.map((el) => el["state"]).sort();
const outletList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "Other"];
const categoryListDiscount = ["All 50%", "Cinemas 20%", "Dental 25%", "Eye Care 25%", "Fashion & Lifestyle 25%",
  "Filling Station 15%", "Fitness 25%", "Gadgets 25%", "Gas Station 10%", "Hospitals 25%", "Kids 25%",
  "Lab/Diagnostics 25%", "Pharmacy 10%", "Restaurants 30%", "Super Markets 30%", "Wholesale Foods 10%"];
const categoryList = ["All", "Cinemas", "Dental", "Eye Care", "Fashion & Lifestyle",
  "Filling Station", "Fitness", "Gadgets", "Gas Station", "Hospitals", "Kids",
  "Lab/Diagnostics", "Pharmacy", "Restaurants", "Super Markets", "Wholesale Foods"];

const BusinessForm = () => {
  const [businessId, setBusinessId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessWeb, setBusinessWeb] = useState("");
  const [businessCat, setBusinessCat] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [outlet, setOutLet] = useState("1");
  const [store, setStore] = useState([]);
  const [marketCha, setMarketCha] = useState("");
  const [salesGoals, setSalesGoals] = useState("");
  const [addtionalInfo, setAddtionalInfo] = useState("");
  const [entryPrice, setEntryPrice] = useState("");
  const [disCategory, setDisCategory] = useState("");
  const [prinContact, setPrinContact] = useState("");
  const [designation, setDesignation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);

  const [showOtherInput, setShowOtherInput] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); 
  const [isUploading, setIsUploading] = useState(false);   
  const [fieldErrors, setFieldErrors] = useState({});     

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (outlet === "Other") {
      setShowOtherInput(true);
    }
  }, [outlet]);

  useEffect(() => {
    if (disCategory.length > 0) {
      let tempValue = categoryList.find((el) => disCategory.includes(el));
      setBusinessCat(tempValue);
    }
  }, [disCategory]);

  useEffect(() => {
    let tempValue = categoryListDiscount.find((el) => el.includes(businessCat));
    setDisCategory(tempValue);
  }, [businessCat]);

  const closeInput = () => {
    setShowOtherInput(false);
    setOutLet("1");
  };

  useEffect(() => {
    const generateUniqueBusinessId = async () => {
      const generateId = () => Math.floor(100000 + Math.random() * 900000).toString();
      let isUnique = false;
      let generatedId;

      while (!isUnique) {
        generatedId = generateId();
        const querySnapshot = await getDocs(
          query(collection(db, "businesses"), where("businessId", "==", generatedId))
        );
        if (querySnapshot.empty) {
          isUnique = true;
        }
      }

      setBusinessId(generatedId);
    };

    generateUniqueBusinessId();
  }, []);

  const validateField = (value, fieldName) => {
    if (!value || value.length === 0) {
      return `${fieldName} is required`;
    }
    return null;
  };

  // const formatFileName = (name) => {
  //   return name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
  // };

  const validateForm = () => {
    const errors = {};

    errors.businessName = validateField(businessName, "Business Name");
    errors.businessCat = validateField(businessCat, "Business Category");
    errors.outlet = validateField(outlet, "Number of Outlets");
    errors.store = store.length === 0 ? "Store Location is required" : null;
    errors.businessEmail = validateField(businessEmail, "Business Email");
    errors.phone = validateField(phone, "Phone Number");
    errors.email = validateField(email, "Email");

    const validErrors = Object.entries(errors).reduce((acc, [key, val]) => {
      if (val) acc[key] = val;
      return acc;
    }, {});

    setFieldErrors(validErrors);

    return Object.keys(validErrors).length === 0;
  };

  // const sendConfirmationEmail = async (userEmail, userName) => {
  //   const emailString = String(userEmail).trim();
  //   const nameString = String(userName).trim();
  //   if (!emailString || !nameString) {
  //     console.error('Email and name cannot be empty');
  //     return;
  //   }
  //   try {
  //     const response = await axios.post(
  //       'https://us-central1-postpaid-bbe31.cloudfunctions.net/addToWaitlist/business',
  //       { email: emailString, name: nameString },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error sending confirmation email:', error);
  //     throw error;
  //   }
  // };

  const sendMandrillEmail = async (toEmail, toName, subject, message) => {
    const mandrillApiKey = "md-31wa9OnclFp6OQat1WR9Xg"; // Replace with your Mandrill API key
    
    const emailData = {
      key: mandrillApiKey,
      message: {
        from_email: "emails@paywithpostpaid.com", // Replace with your sender email
        from_name: "Postpaid", // Replace with your sender name
        to: [
          {
            email: toEmail,
            name: toName,
            type: "to",
          },
        ],
        subject: subject,
        html: message, // HTML content of the email
      },
    };
  
    try {
      const response = await axios.post(
        "https://mandrillapp.com/api/1.0/messages/send.json",
        emailData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error sending email through Mandrill:", error);
      throw new Error("Failed to send email");
    }
  };

  const sanitizeBusinessName = (name) => {
    return name
      .replace(/[^a-zA-Z0-9-_]/g, '_') 
      .replace(/_{2,}/g, '_') 
      .replace(/^_|_$/g, '') 
      .toLowerCase();
  };

  const handleLogoUpload = async (file) => {
    if (!file || !businessName) return null;

    if (file.size > 5 * 1024 * 1024) {
      throw new Error("File size exceeds 5MB limit.");
    }

    if (!file.type.startsWith('image/')) {
      throw new Error("File must be an image.");
    }

    const fileExtension = file.name.split('.').pop();
    const sanitizedBusinessName = sanitizeBusinessName(businessName);
    const uniqueId = uuidv4().slice(0, 8); // Generate a short unique identifier

    const fileName = `${sanitizedBusinessName}_${uniqueId}.${fileExtension}`;

    const storageRef = ref(storage, `business_logos/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          setIsUploading(true);
        },
        (error) => {
          console.error("Upload failed:", error);
          setIsUploading(false);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setIsUploading(false);
            resolve(downloadURL);
          } catch (error) {
            console.error("Failed to get download URL:", error);
            setIsUploading(false);
            reject(error);
          }
        }
      );
    });
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; 
    }
  
    setIsSubmitting(true);
    setError(null);
  
    try {
      let logoURL = null;
      if (companyLogo) {
        try {
          logoURL = await handleLogoUpload(companyLogo);
        } catch (uploadError) {
          console.error("Logo upload failed:", uploadError);
          setError(uploadError.message || "Logo upload failed. Proceeding without logo.");
          throw uploadError;
        }
      }
  
      const businessData = {
        businessName,
        businessCat,
        businessEmail,
        outlet,
        store,
        marketCha,
        salesGoals,
        addtionalInfo,
        entryPrice,
        disCategory,
        prinContact,
        designation,
        phone,
        email,
        logoURL,
        timestamp: new Date(),
      };
  
      // Save the business data to Firestore
      const docRef = await addDoc(collection(db, "businesses"), businessData);
  
      // Send confirmation email after successful Firestore entry
      // if (businessEmail && businessName) {
      //   try {
      //     const emailResult = await sendConfirmationEmail(businessEmail, businessName);
      //     if (!emailResult.success) {
      //       throw new Error(emailResult.message || "Failed to send confirmation email");
      //     }
      //   } catch (emailError) {
      //     console.error("Error sending confirmation email:", emailError);
      //     setError("Failed to send confirmation email. Please try again later.");
      //   }
      // }

      // Send Mandrill email after successful Firestore entry
      const emailSubject = "Welcome to Postpaid for Business!";
      const emailMessage = `
        <h1>Dear ${businessName},</h1>
        <p>A warm welcome to Postpaid!</p>
        <p>We're excited to have you on board as a valued business partner. By joining our Discount Service, you have taken the first step towards unlocking new revenue streams, increasing customer loyalty, and growing your brand.</p>
        <p>With Postpaid for Business, here’s what you can look forward to:</p>
        <ul>
        <li><strong>Customer Acquisition:</strong> Attract new customers when you offer exclusive discounts and rewards through Postpaid to our over 3m+ eligible consumer network.</li>
        <li><strong>Customer Retention:</strong> Foster loyalty with existing customers through tailored promotions and discounts by amplifying your brand’s presence through co-branding opportunities.</li>
        <li><strong>Growth Opportunities:</strong> Expand your reach and revenue streams.</li>
        <li><strong>Merchant Partnerships:</strong> Collaborations with businesses to offer exclusive deals to you, our Postpaid for Business Partner.</li>
        <li><strong>Community Engagement:</strong> A platform for consumers and businesses to interact, share, and grow together.</li>
        </ul>
        <p>As part of Postpaid for Business, you will be able to verify Postpaid Members and their discount codes via www.paywithpostpaid.com/business-verify with your unique Business ID here ${businessId} . You should keep this link handy at all times. No login or any other processes required to access the link.</p>
      `;

      try {
        const emailResult = await sendMandrillEmail(businessEmail, businessName, emailSubject, emailMessage);
        console.log("Email sent successfully:", emailResult);
      } catch (emailError) {
        console.error("Error sending email via Mandrill:", emailError);
        setError("Failed to send confirmation email. Please try again later.");
      }
  
      setSubmitSuccess(true);
      setTimeout(() => {
        navigate("/business");
      }, 2000);
    } catch (error) {
      console.error("Error saving business information:", error);
      setError("Failed to save business information. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <>
      <div className={styles.container}>
        <div className={styles.backContainer} onClick={() => navigate('/business')}>
          <img src={LeftArrow} alt="Arrow Left" />
          <p className={styles.backText}>Go Back</p>
        </div>

        <div className={styles.mainForm}>
          <div className={styles.containerTwo}>
            <p className={styles.titleText}>Business Sign up</p>
            <p className={styles.smallText}>Kindly enter your details to join Postpaid for Business!</p>
          </div>

          {error && (
            <div className={styles.errorContainer}>
              <img src={errorIcon} alt="Error Icon" />
              <p className={styles.errorText}>{error}</p>
            </div>
          )}

          <p className={styles.logoText}>Business Logo</p>
          <DragAndDrop setCompanyLogo={setCompanyLogo} showInputError={fieldErrors.companyLogo} companyLogo={companyLogo} />
          <p className={styles.logoText}>Click or drag and drop to upload your business Logo</p>
          <p className={styles.logoTextTwo}>Supports PNG, JPG, JPEG (Max 5MB)</p>

          <div className={styles.containerThree}>
            <div className={styles.partOne}>
            <TextField 
                placeHolder={"Enter Business Name"} 
                type={"text"} 
                setValue={setBusinessName} 
                value={businessName} 
                label={"Name Of Business"} 
                showInputError={fieldErrors.businessName}
                errorMessage={fieldErrors.businessName} 
              />
            </div>
            <div className={styles.partOne}>
              <DropDown
                label="Category Of Business"
                data={categoryList}
                value={businessCat}
                setValue={setBusinessCat}
                showError={fieldErrors.businessCat}
                errorMessage={fieldErrors.businessCat}
              />
            </div>
          </div>

          <div className={styles.containerThree}>
            <div className={styles.partOne}>
              {showOtherInput ? (
                <>
                  <img src={closeIcon} onClick={closeInput} className={styles.closeInput} />
                  <TextField 
                    placeHolder={"Enter number"} 
                    type={"number"} 
                    setValue={setOutLet} 
                    value={outlet} 
                    label={"No. Of Outlet"} 
                    showInputError={fieldErrors.outlet}
                    errorMessage={fieldErrors.outlet}
                  />
                </>
              ) : (
                <DropDown
                  label="No. Of Outlet"
                  data={outletList}
                  value={outlet}
                  setValue={setOutLet}
                  showInputError={fieldErrors.outlet}
                  errorMessage={fieldErrors.outlet}
                />
              )}
            </div>
            <div className={styles.partOne}>
              <MultipleSelect 
                label={"Location Of Outlet"} 
                data={stateList} 
                value={store}
                setvalue={setStore}
                showInputError={fieldErrors.store}
                errorMessage={fieldErrors.store}
              />
            </div>
          </div>

          <div className={styles.containerThree}>
            <div className={styles.partOne}>
              <TextField 
                placeHolder={"Enter Business Email"} 
                type={"email"} 
                setValue={setBusinessEmail} 
                value={businessEmail} 
                label={"Business Email"} 
                showInputError={fieldErrors.businessEmail}
                errorMessage={fieldErrors.businessEmail}
              />
            </div>
            <div className={styles.partOne}>
              <TextField 
                placeHolder={"Enter Business Website"} 
                type={"text"} 
                setValue={setBusinessWeb} 
                value={businessWeb} 
                label={"Website"}
                showInputError={fieldErrors.businessWeb}
                errorMessage={fieldErrors.businessWeb} 
              />
            </div>
          </div>

          <div className={styles.containerFour}>
            <TextArea 
              label={"Marketing Challenges"} 
              placeHolder={"Describe your marketing challenges"} 
              setValue={setMarketCha} 
              value={marketCha}
              showInputError={fieldErrors.marketCha}
              errorMessage={fieldErrors.marketCha} 
            />
          </div>

          <div className={styles.containerFour}>
            <TextArea 
              label={"Sales Goals"} 
              placeHolder={"Describe your sales goals"} 
              setValue={setSalesGoals} 
              value={salesGoals}
              showInputError={fieldErrors.salesGoals}
              errorMessage={fieldErrors.salesGoals} 
            />
          </div>

          <div className={styles.containerFour}>
            <TextArea 
              label={"Additional Information"} 
              placeHolder={"Enter any additional information"} 
              setValue={setAddtionalInfo} 
              value={addtionalInfo}
              showInputError={fieldErrors.addtionalInfo}
              errorMessage={fieldErrors.addtionalInfo} 
            />
          </div>

          <div className={styles.containerThree}>
            <div className={styles.partOne}>
              <TextField 
                placeHolder={"Enter Product/Service Entry Price"} 
                type={"number"} 
                setValue={setEntryPrice} 
                value={entryPrice} 
                label={"Product/Service Entry Price"} 
                showInputError={fieldErrors.entryPrice}
                errorMessage={fieldErrors.entryPrice} 
              />
            </div>
            <div className={styles.partOne}>
              <DropDown
                label="Discount Category"
                data={categoryListDiscount}
                value={disCategory}
                setValue={setDisCategory}
                showInputError={fieldErrors.disCategory}
                errorMessage={fieldErrors.disCategory} 
              />
            </div>
          </div>

          <div className={styles.containerThree}>
            <div className={styles.partOne}>
              <TextField 
                placeHolder={"Enter Principal Contact Name"} 
                type={"text"} 
                setValue={setPrinContact} 
                value={prinContact} 
                label={"Name Of Principal Contact"} 
                showInputError={fieldErrors.prinContact}
                errorMessage={fieldErrors.prinContact} 
              />
            </div>
            <div className={styles.partOne}>
              <TextField 
                placeHolder={"Enter Designation"} 
                type={"text"} 
                setValue={setDesignation} 
                value={designation} 
                label={"Designation"}
                showInputError={fieldErrors.designation}
                errorMessage={fieldErrors.designation} 
              />
            </div>
          </div>

          <div className={styles.containerThree}>
            <div className={styles.partOne}>
              <TextField 
                placeHolder={"Enter Phone Number"} 
                type={"text"} 
                setValue={setPhone} 
                value={phone} 
                label={"Phone Number"} 
                showInputError={fieldErrors.phone}
                errorMessage={fieldErrors.phone} 
              />
            </div>
            <div className={styles.partOne}>
              <TextField 
                placeHolder={"Enter Email"} 
                type={"email"} 
                setValue={setEmail} 
                value={email} 
                label={"Email"} 
                showInputError={fieldErrors.email}
                errorMessage={fieldErrors.email} 
              />
            </div>
          </div>

          {error && (
          <div className={styles.errorContainer}>
            <img src={errorIcon} alt="Error Icon" />
            <p className={styles.errorText}>{error}</p>
          </div>
        )}

        {submitSuccess && (
          <div className={styles.successContainer}>
            <p className={styles.successText}>Business information submitted successfully!</p>
          </div>
        )}

          {error && <p className={styles.errorText}>{error}</p>}

          <div 
          className={`${styles.waitListBtn} ${isSubmitting ? styles.disabled : ''}`} 
          onClick={!isSubmitting ? handleSubmit : undefined}
        >
          {isSubmitting ? "Submitting..." : "Sign Up My Business"}
          </div>
        </div>
      </div>
      <FooterBus />
    </>
  );
};

export default BusinessForm; 