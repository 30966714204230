import React, { useState, useEffect, useRef, useContext } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.css"
import { AppContext } from "../../context";
import PostpaidLogo from "../../assets/images/postpaidLogo.svg"
import Search from "../../assets/images/search.svg"
import Notify from "../../assets/images/notifications.svg"


const ListDropDown = ({setShowDroddown}) => {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDroddown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);  

  const navigate = useNavigate();
  const location = useLocation();

  const goToPage = (url) => {
    navigate(url);
    setShowDroddown(false)
  }

  const checkPath = (testParams) => {
    if (location.pathname === testParams) {
      return true
    } else {
      return false
    }
  }

  return (
    <div  ref={ref} className={styles.listContainer}>
      <p 
        className={checkPath('/dashboard') ? styles.textTwoActive : styles.textTwo} 
        onClick={() => goToPage('/dashboard')}>Account Information
      </p>
      <p 
        // className={styles.textTwo} 
        className={checkPath('/dashboard/cards') ? styles.textTwoActive : styles.textTwo}
        onClick={() => goToPage('/dashboard/cards')}>Card
      </p>
      <p 
        className={checkPath('/dashboard/discount-offers') ? styles.textTwoActive : styles.textTwo} 
        onClick={() => goToPage('/dashboard/discount-offers')}>Discount History
      </p>
      <p 
        className={checkPath('/dashboard/transaction-history') ? styles.textTwoActive : styles.textTwo}
        onClick={() => goToPage('/dashboard/transaction-history')}>Transaction History
      </p>
      <p 
        className={checkPath('/dashboard/privacy') ? styles.textTwoActive : styles.textTwo}
        onClick={() => goToPage('/dashboard/privacy')}>Security and Privacy
      </p>
      <p 
        className={checkPath('/dashboard/customer-service') ? styles.textTwoActive : styles.textTwo}
        onClick={() => goToPage('/dashboard/customer-service')}>Customer Service
      </p>
      <p className={styles.logoutText} onClick={() => goToPage('/login')}>Log-out</p>

    </div>
  )
}

const Header = () => {
  const navigate = useNavigate();

  const [showDropdown, setShowDroddown] = useState(false)

  const goHome = () => {
    navigate('/')
  }

  const {subscribed} = useContext(AppContext)

  return (
    <div className={styles.container}>
      <div className={styles.containerTwo} onClick={goHome}>
        <img src={PostpaidLogo} alt="Company Pics" />
        <p className={styles.textOne}>Postpaid</p>
      </div>

      <div className={styles.containerThree}>
        {
          subscribed && <>
            <input 
              type="text" 
              className={styles.input} 
              placeholder="Search businesses, products and coupons"
            />
            <div className={styles.search}>
              <img src={Search} alt="search icon" />
            </div>
          </>
        }

      </div>

      {
        subscribed &&
        <div className={styles.containerFour}>
          <img src={Notify} alt="Notify Bell" />
          <div className={styles.containerFive}>
            <p className={styles.textprofile}>Matuidi</p>
            <div className={styles.icon} onClick={() => setShowDroddown((prev) => !prev)}>
              <ArrowDropDownIcon />
            </div>

            {
              showDropdown && <ListDropDown setShowDroddown={setShowDroddown} />
            }
          </div>
      </div>
      }
    </div>
  )
}

export default Header;