import React from "react"
import DarkBlueSpiral from "../../assets/images/DarkBlueSpiral.svg";
import styles from "./styles.module.css"


const WorldOfConsumers = () => {
  return (
    <section className={styles.container}>
      <img src={DarkBlueSpiral} className={styles.spiral__img} />

      <div className={styles.textContainer}>
        <p className={styles.hero__section__text}>Enter the World of Consumers</p>

        <p className={styles.smallText}>
          Our marketing solutions put your business in front of our highly engaged and eligible 
          partner consumer community.
        </p>
      </div>

      <div className={styles.content}>
        <div className={styles.contentItem}>
          <p className={styles.hero__section__text}>$162b</p>
          <p className={styles.smallTextTwo}>Consumer spending forecast for 2024</p>
        </div>
        <div className={styles.contentItem}>
          <p className={styles.hero__section__text}>76m</p>
          <p className={styles.smallTextTwo}>Shoppers in Nigeria (and growing)</p>
        </div>
        <div className={styles.contentItem}>
          <p className={styles.hero__section__text}>5m</p>
          <p className={styles.smallTextTwo}>Postpaid Consumer Membership Reach</p>
        </div>
      </div>


    </section>
  )
}

export default WorldOfConsumers