import React, { useState, useEffect } from "react";
import axios from "axios";
import cardInfo from "../../assets/images/cardInfo.svg";
import styles from "./styles.module.css";
import { getAuth } from "firebase/auth";

const Cards = () => {
  const [cardNumber, setCardNumber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCardDetails = async () => {
      setLoading(true);
      setError("");

      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken(); // Get Firebase auth token
        const uid = auth.currentUser.uid; // Get current user's UID

        const response = await axios.post(
          "/api/card-details",
          { uid }, // Pass UID in request body
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include Firebase token in headers
            },
          }
        );

        setCardNumber(response.data.cardNumber);
      } catch (err) {
        console.error("Error fetching card details:", err);
        setError(err.response?.data?.error || "Failed to fetch card details.");
      } finally {
        setLoading(false);
      }
    };

    fetchCardDetails();
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.headertext}>Card Information</h1>

      <div className={styles.contentMain}>
        <img src={cardInfo} alt="Card Icon" className={styles.img} />
        {loading ? (
          <p className={styles.textOne}>Loading card information...</p>
        ) : error ? (
          <p className={styles.errorText}>{error}</p>
        ) : (
          <p className={styles.textOne}>Card Number: {cardNumber}</p>
        )}
      </div>
    </div>
  );
};

export default Cards;