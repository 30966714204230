import { createContext } from "react"

export const AppContext = createContext({
  pageNav: "",
  setPageNav: () => {},
  subscribed: false,
  setSubScribe: () => {},
  openJoinNow: false,
  setOpenJoinNow: () => {}
})
