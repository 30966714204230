import React, { useState } from 'react';
import axios from 'axios';
import styles from "./styles.module.css";
import VerifyCodes from './VerifyCodes';

const Main = () => {
    const [membershipId, setMembershipId] = useState("");
    //const [businessId, setBusinessId] = useState(""); // New state for businessId
    const [discountCode, setDiscountCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleVerify = async () => {
        setLoading(true);
        setMessage("");
        setError("");

        try {
            // Send the API request with businessId included
            const response = await axios.post(
                "https://us-central1-postpaid-bbe31.cloudfunctions.net/app/api/business/use",
                { membershipId, discountCode }
            );

            setMessage(`Success: ${response.data.message}`);
        } catch (err) {
            setError(err.response?.data?.message || "An error occurred.");
            console.error("Verification error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className={styles.verifymain__container}>
                <div className={styles.verifymain__content}>
                    <div className={styles.verifymain__section}>
                        <div className={styles.verifycard}>
                            <p className={styles.verifymain__discount}>Discount Code Verification</p>
                            <div className={styles.verifymain__input__container}>
                                {/* <input
                                    type="text"
                                    placeholder="Business ID"
                                    className={styles.verifymain__input}
                                    value={businessId}
                                    onChange={(e) => setBusinessId(e.target.value)}
                                /> */}
                                <input
                                    type="text"
                                    placeholder="Membership ID"
                                    className={styles.verifymain__input}
                                    value={membershipId}
                                    onChange={(e) => setMembershipId(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Input discount code"
                                    className={styles.verifymain__input}
                                    value={discountCode}
                                    onChange={(e) => setDiscountCode(e.target.value)}
                                />
                                <button
                                    className={styles.verifymain__button}
                                    onClick={handleVerify}
                                    disabled={loading}
                                >
                                    {loading ? "Verifying..." : "Verify"}
                                </button>
                            </div>
                            {message && <p className={styles.successMessage}>{message}</p>}
                            {error && <p className={styles.errorMessage}>{error}</p>}
                        </div>
                    </div>
                    <div className={styles.verifymain_codes__content}>
                        <VerifyCodes />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;