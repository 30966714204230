import React, { useState, useContext } from "react";
import CaretDown from "../../assets/images/CaretDown.svg";
import { RxCaretUp } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { AppContext } from "../../context";
import SideDrawer from "../../components/Drawer/SideDrawer";
import androidMenu from "../../assets/images/androidMenu.svg";
import PostpaidLogo from "../../assets/images/postpaidLogo.svg";
import styles from "./styles.module.css";

const faqs = [
  {
    question: "What is Postpaid?",
    answer:
      "We connect consumers with exclusive discounts, promotions, and rewards, while helping businesses drive growth, increase customer loyalty, and reach new heights.",
  },
  {
    question: "How do I register for Postpaid?",
    answer:
      "You can join our waitlist here to be the first to gain access to our exclusive discount offers.",
  },
  {
    question: "How do I use Postpaid?",
    answer:
      "Postpaid offers discounts across over 12 shopping categories with discounts ranging from 5% - 50% whenever you shop.",
  },
  {
    question: "How much can I save using Postpaid?",
    answer:
      "You can check our savings calculator here to learn more about what you save across various shopping categories.",
  },
];

const Help = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { pageNav, setPageNav } = useContext(AppContext);
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <h1 className={styles.faqs_header}>FAQs</h1>
      <div className={styles.faq__question__container}>
        {faqs.map((faq, index) => (
          <div
            className={`${styles.faq_item} ${activeIndex === index ? styles.active : ""
              }`}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <div className={styles.faq_question}>
              {faq.question}
              <span className={styles.faq_arrow}>
                {activeIndex === index ? (
                  <RxCaretUp className={styles.caret__up} />
                ) : (
                  <img src={CaretDown} className={styles.caret__down} />
                )}
              </span>
            </div>
            {activeIndex === index && (
              <div className={styles.faq_answer}>{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
      <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Help;
