import React, {useState} from "react"
import { useNavigate } from "react-router-dom";
import groupLogo from "../../assets/images/logoGroup.svg"
import styles from "./styles.module.css"

const Upload = () => {
  const navigate = useNavigate()

  const [preview, setPreview] = useState(null)
  const [companyLogo, setCompanyLogo] = useState(null);

  const handleFileChange = (event) => {
    let uploadFile = event.target.files[0]

    setCompanyLogo(uploadFile)
    setPreview(URL.createObjectURL(uploadFile))
  }

  const formatString = () => {
    if (companyLogo.name.length > 10) {
      return `${companyLogo.name.substring(0,10)}...`
    } 
    return companyLogo.name
  }

  const goHome = () => {
    navigate("/")
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={goHome}>
        <img src={groupLogo} alt="Logo" />
      </div>

      <div className={styles.card}>
        <p className={styles.textOne}>Upload Photo</p>

        {
          preview ? <img src={preview} alt='Profile' className={styles.companyLogo} /> :
          <div className={styles.photodark}>

          </div>
        }

        <div className={styles.inputContainer}>
          <div className={styles.inputOne}>
            <input type="file" onChange={handleFileChange} className={styles.inputTwo} />
            <p className={styles.filetext}>Choose File</p>
          </div>

            {
              companyLogo ? 
              <p>{formatString()}</p> : <p className={styles.textTwo}>No File Chosen</p>
            }
        </div>

        <button className={styles.btn} onClick={() => navigate('/verify-email')}>Upload</button>
      </div>
    </div>
  )
}

export default Upload;