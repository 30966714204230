import React, {useState} from "react";
import EvStationIcon from '@mui/icons-material/EvStation';
import MedicationIcon from '@mui/icons-material/Medication';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import dentalIcon from "../../assets/images/dentalIcon.svg"
import healthIcon from "../../assets/images/healthIcon.svg"
import image40 from "../../assets/images/image40.jpeg"
import image41 from "../../assets/images/image41.png"
import image42 from "../../assets/images/image42.jpeg"
import image43 from "../../assets/images/image43.jpeg"
import image44 from "../../assets/images/image44.png"
import supplyaIcon from "../../assets/images/supplyaIcon.svg"
import whatsapp from "../../assets/images/whatsapp.svg"
import logo1 from "../../assets/images/logo1.svg"
import AppleIcon from '@mui/icons-material/Apple';
import styles from "./styles.module.css"

const ItemCategory = ({ CatIcon, title, widthParam}) => {

  return (
    <div 
      style={{minWidth: "max-content", backgroundColor: "white", padding: "0px 20px" }} 
      className={styles.itemContainer} 
    >
      {CatIcon}
      <p className={styles.textTwo} style={{color: "#183887" }}>{title}</p>
    </div>
  )
}

const ShopCategories = () => {

  return (
    <div className={styles.container}>
      <p className={styles.textOne}>Shop from our various categories</p>

      <div className={styles.mainContent}>
        <div style={{ overflowX: "scroll", width: "95vw", scrollbarWidth: "none"}} >
          <div className={styles.containerTwo}>
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Fuel & Gas"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<MedicationIcon style={{color: "#183887"}} />} 
              title="Pharmacy"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<FitnessCenterIcon style={{color: "#183887"}} />} 
              title="Fitness"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Gadgets"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Dental Care"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Entertainment"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Toys & Games"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<AppleIcon style={{color: "#183887"}} />} 
              title="Wholesale Foods"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Eye Care"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Health Care"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Apparel"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Groceries"
            />
            <ItemCategory 
              widthParam="200px" 
              CatIcon={<EvStationIcon style={{color: "#183887"}} />} 
              title="Restaurants"
            />
          </div>
        </div>
      </div>

      <div className={styles.mainContent}>
        <p className={styles.textOne}>We partner with the best</p>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <img src={dentalIcon} alt="Dental Icon" />
          </div>
          <div className={styles.card}>
            <img src={healthIcon} alt="Health Icon" />
          </div>
          <div className={styles.card}>
            <img src={supplyaIcon} alt="Health Icon" />
          </div>
          <div className={styles.card}>
            <img src={whatsapp} alt="Health Icon" />
          </div>
          <div className={styles.card}>
            <img className={styles.img} src={image40} alt="Health Icon" />
          </div>

          <div className={styles.card}>
            <img className={styles.img} src={image41} alt="Health Icon" />
          </div>

          <div className={styles.card}>
            <img className={styles.img} src={image42} alt="Health Icon" />
          </div>

          <div className={styles.card}>
            <img className={styles.img} src={image43} alt="Health Icon" />
          </div>

          <div className={styles.card}>
            <img className={styles.img} src={image44} alt="Health Icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopCategories