import React from 'react';
import styles from "./styles.module.css"
import BusinessHeader from '../../BusinessHeader/BusinessHeader';
import Sidebar from '../Sidebar/Sidebar';
import MainContent from '../MainContent/MainContent';

const BusinessDashboard = () => {
    return (
        <div>
            <BusinessHeader />
            <div className={styles.businessdashboard__content}>
                <Sidebar />
                <MainContent />
            </div>
        </div>
    );
}

export default BusinessDashboard;