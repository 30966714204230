import React from 'react';
import ReactApexChart from 'react-apexcharts';

class DoughnutChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [44, 55, 41, 17, 15],
            options: {
                chart: {
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 150
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }



    render() {
        return (
            <div>
                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="donut" style={{ width: "450px", height: "380px", margin: "0 auto" }} />
                </div>
                <div id="html-dist"></div>
            </div>
        );
    }
}


export default DoughnutChart;