import React from "react"

import styles from "./styles.module.css"

const TextArea = ({setValue, label, value, placeHolder, showInputError}) => {
  
  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleError = () => {
    if (showInputError && value.length === 0) {
      return true;
    }
    return false
  }


  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>

      <textarea 
        placeholder={placeHolder ? placeHolder : ""} 
        // className={styles.textArea} 
        className={handleError() ? styles.errorInput : styles.textArea}
        value={value} 
        onChange={handleChange}
      >
      </textarea>

      {
        handleError() && <p className={styles.errorText}>Please Enter {label} Field!</p>
      }
    </div>
  )
}

export default TextArea