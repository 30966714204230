import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import groupLogo from "../../assets/images/logoGroup.svg"
import logoSide from "../../assets/images/forgotPass.svg"
import styles from "./styles.module.css"

const ForgotPassword = () => {
  const [userData, setUserData] = useState('');

  const handleUserChange = (e) => {
    setUserData(e.target.value)
  }

  const navigate = useNavigate();

  const goHome = () => {
    navigate('/')
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={goHome}>
        <img src={groupLogo} alt="Logo" />
      </div>

      <div className={styles.containerTwo}>
        <div className={styles.sideLogoContainer}>
          <img height={600} src={logoSide} alt="Logo" />
        </div>

        <div className={styles.formContainer}>
          <div className={styles.formInner}>
            <div className={styles.textContainer}>
              <p className={styles.textOne}>Forgot password?</p>
              <p className={styles.textTwo}>
                Don’t worry! It happens. Please enter the email address associated with your account.</p>
            </div>

            <input 
              placeholder="E-mail / Phone Number"  
              onChange={handleUserChange}
              value={userData}
              className={styles.input}
            />

            <button className={styles.btn} onClick={() => navigate('/otp')}>Request OTP</button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;

