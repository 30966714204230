import React from "react"
import styles from "./styles.module.css"
// import LightBlueSpiral from "../assets/images/LightBlueSpiral.svg";
import Image25 from "../../assets/images/Image25.svg"
import { useNavigate } from "react-router-dom";

const BusinessProfit = () => {
  
  const navigate = useNavigate()

  const goToBusinessForm = () => {
    navigate("/business-form")
  }

  return (
    <section className={styles.container}>
      {/* <img src={LightBlueSpiral} className={styles.spiral__img} /> */}
      <div className={styles.partOne}>
        <div className={styles.partOneInner}>
          <p className={styles.hero__section__text}>A Profitable Partnership For your Business!</p>

          <p className={styles.hero__section__text_i}>
            Empowering businesses of all size to run more sustainably and profitably by providing a 
            way that improves businesses, save cost and offer more value to customers.
          </p>

          <div onClick={goToBusinessForm} className={styles.waitList_btn}>Sign Up My Business</div>
        </div>
      </div>

      <div className={styles.partTwo}>
        <div className={styles.partTwoInner}>
          <img src={Image25} className={styles.image25} />
        </div>
      </div>

    </section>
  )
}

export default BusinessProfit