import React, { useState } from "react";
import styles from "./styles.module.css"
import { Divider } from "@mui/material";
import androidMenu from "../../../../assets/images/androidMenu.svg"
import BusinessLogo from "../../../../assets/images/BusinessLogo.svg";
import SideDrawer from "../../../../components/Drawer/SideDrawer";
import verified from "../../../../assets/images/verified.svg";


const BusinessHeader = () => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.businessheader__content}>
                    <div className={styles.businessheader__img}>
                        <img src={BusinessLogo} className={styles.businesslogo} alt="business__logo" />
                    </div>
                    <div className={styles.verifysidebar__context}>
                        <p className={styles.verifysidebar__verify}>Verified Partner</p>
                        <img src={verified} className={styles.verifysidebar__verify} alt="verify__icon" />
                    </div>
                    <img onClick={() => setIsOpen(true)} src={androidMenu} alt="android menu bar" className={styles.menuBar} />
                </div>

                <div className={styles.containerFour}>
                    <Divider style={{ height: "30px" }} orientation="vertical" variant="middle" flexItem />
                </div>

            </div>
            <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
}

export default BusinessHeader;