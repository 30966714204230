import React from "react";
import Postpaid from "../../assets/images/Postpaid.svg";
import AlphaJam from "../../assets/images/Alpha&Jam.svg";
import OatsTech from "../../assets/images/OatsTech.svg";

import styles from "./styles.module.css"

const FooterBus = () => {
  return (
    <footer className={styles.footerBus}>
      <div className={styles.footer__container}>
        <div  className={styles.footer__first__content}>
          <div className={styles.footer__first__content_i}>
            <img
              src={Postpaid}
              className={styles.postpaidfooter__logo}
              alt="postpaid-logo"
            />
            <p className={styles.footer_brand}>
              Post<span className={styles.footer__text}>paid</span>
            </p>
          </div>

          <div className={styles.footer__privacy__content}>
            <p className={styles.footer__privacy__text}>
              Copyright ©️2024 Pay With Postpaid | All Rights Reserved | An OATS Africa Product
            </p>
          </div>
        </div>

        <div className={styles.footer__second__content} >
          <p className={styles.footer__second__text}>Our Marketing Solution Partners</p>
          <div className={styles.footer__second__context}>
            <img src={AlphaJam} className="alpha__img" />
            <img src={OatsTech} className="oats__img" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterBus;
