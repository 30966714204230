import React, { useEffect } from "react"

import styles from "./styles.module.css"

const DropDown = ({data, setValue, label, value}) => {
  const handleChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    setValue("");
  }, [])

  return (
    <div className={styles.container}>
      <select className={styles.select} value={value} onChange={handleChange} aria-placeholder="Test">
        <option value="" disabled>
          {label}
        </option>
        {data.map((element, key) => 
          <option value={element} key={`${element}${key}`}>{element}</option>
        )}
      </select>
    </div>
  )
}

export default DropDown