import React from "react"

import CircleBanner from "../../assets/images/CircledBanner.svg"
import CircleBanner2 from "../../assets/images/CircledBanner2.svg"
import CircleBanner3 from "../../assets/images/CircledBanner3.svg"
import Calcuator from "../Calculator/Calculator"
import styles from "./styles.module.css"


const CalcDiscount = () => {
  return (
    <div className={styles.container}>
      <p className={styles.textOne}>
        Let’s put money back in your pocket with 50% off Membership 
        Registration Fee and up to 50% in discounts.
      </p>

      <div className={styles.containerTwo}>
        <div className={styles.flexOne}>
          <div className={styles.imgContainer}>
            <img src={CircleBanner} alt="Circle Banner" className={styles.imageOne} />
            <img src={CircleBanner2} alt="Circle Banner" className={styles.imageTwo} />
            <img src={CircleBanner3} alt="Circle Banner" className={styles.imageThree} />
          </div>
        </div>
        <div className={styles.flexTwo}>
          <div className={styles.innerContainer}>
            <div className={styles.rect}></div>
            <p className={styles.textTwo}>
              Get 50% off when you register to join Postpaid on or before 31st December 
              2024 to unlock amazing benefits and rewards
            </p>

            <Calcuator />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalcDiscount