import React, { useState } from "react";
import closeIcon from "../../../assets/images/cancelWhite.svg"
import styles from "./styles.module.css"

const MultipleSelect = ({label, value, data, setvalue}) => {
  const [openDrop, setOpenDrop] = useState(false)

  const handleDrop = () => {
    setOpenDrop((prev) => !prev)
  }

  const checkItem = (item) => {
    return value.includes(item)
  }

  const handleClick = (item) => {
    if (value.includes(item) || value.length > 3) {
     return false 
    }
    let tempValue = [...value, item]
    setvalue(tempValue)
    setOpenDrop(false)
  }

  const handleClose = (e, item) => {
    e.stopPropagation()

    let tempValue = value.filter(el => el !== item)
    setvalue(tempValue)
  }

  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>

      <div className={styles.select} onClick={handleDrop}>
        {
          value.map((el, i) => (
            <div key={i} className={styles.containerTwo}>
              <p className={styles.textTwo}>{el}</p>
              <img className={styles.closeicon} src={closeIcon} alt="close Icon" onClick={(e) => handleClose(e, el)} />
            </div>
          ))
        }
      </div>

      {
        openDrop &&
        <div className={styles.dropDownList}>
          {
            data.map((el, i) => (
              <p 
                key={i} 
                className={checkItem(el) ? styles.activeItem : styles.item}
                onClick={() => handleClick(el)}
              >{el}</p>
            ))
          }
        </div>
      }

    </div>
  ) 
}

export default MultipleSelect