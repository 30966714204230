import React, {useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import SideDrawer from "../../components/Drawer/SideDrawer";
import PostpaidLogo from "../../assets/images/postpaidLogo.svg"
import Slider1 from "../../assets/images/Slider1.jpg"
import Slider2 from "../../assets/images/Slider2.jpg"
import androidMenu from "../../assets/images/androidMenu.svg"
import styles from "./styles.module.css"
import Categories from "../../components/Categories/Categories";
import WhatIsPostpaid from "../../components/WhatIsPostpaid/WhatIsPostpaid";
import ShopCategories from "../../components/ShopCategories/ShopCategories";
import ShopPostpaid from "../../components/ShopPostpaid/ShopPostpaid";
import CalcDiscount from "../../components/CalcDiscount/CalcDiscount";
import sliderIcon from "../../assets/images/sliderIcon.svg"
import slider2 from "../../assets/images/slider2.svg"
import Membership from "../../components/Membership/Membership";
import FAQsPage from "../../components/FAQsPage/FAQsPage";
import Footer from "../../components/Footer/Footer";
import GiftingSection from "../../components/GiftingSection/GiftingSection";
import AllOffers from "../../components/Categories/AllOffers";
// import HealthySavings from "../../components/HealthySavings/HealthySavings";
import Help from "../../components/Help/Help";
import ShopNow from "../../components/ShopNow/ShopNow";
import JoinNow from "../../components/JoinNow/JoinNow";
import HealthySavings from "../../components/HealthySavings/HealthySavings";
import { AppContext } from "../../context";

const RenderMain = () => {
  const {setOpenJoinNow} = useContext(AppContext)

  const handleClick = () => {
    setOpenJoinNow(true)
  }

  
  return (
    <>

      <div className={styles.testContTwo}>
        <div className={styles.testCont}>
          <div className={styles.sliderContainer}>
            <img src={sliderIcon} alt="Slider Icon" className={styles.slider} />
            <button onClick={handleClick} className={styles.btnShop}>
              Shop Now
            </button>
          </div>

          <div className={styles.sliderContainer}>
            <img src={Slider1} alt="Slider Icon" className={styles.slider} />
            <button onClick={handleClick} className={styles.btnShop}>
              Shop Now
            </button>
          </div>

          <div className={styles.sliderContainer}>
            <img src={Slider2} alt="Slider Icon" className={styles.slider} />
            <button onClick={handleClick} className={styles.btnShop}>
              Join Now
            </button>
          </div>
        </div>
      </div>

      <div className={styles.hiddenHeight}></div>

      <WhatIsPostpaid />

      <ShopCategories />

      <ShopPostpaid />

      <CalcDiscount />

      <Membership />

      <FAQsPage />
    </>
  )
}

const RenderGift = () => {
  const [openShopNow, setOpenShopNow] = useState(false)
  const handleClick = () => {
    setOpenShopNow(true)
  }
  return (
    <>
      <div className={styles.sliderContainer}>
        <img src={slider2} alt="Slider Icon" className={styles.slider} />
        <button onClick={handleClick} className={styles.btnShop}>
          Share Gift
        </button>
      </div>
      <div className={styles.hiddenHeight}></div>

      <GiftingSection />

      <ShopNow openShopNow={openShopNow} setOpenShopNow={setOpenShopNow} />
    </>
  )
}

const Landing = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {pageNav, setPageNav, setOpenJoinNow, openJoinNow} = useContext(AppContext)

  const navigate = useNavigate()

  const goHome = () => {
    setPageNav("")
  }


  const joinNow = () => {
    setOpenJoinNow(true)
  }

  const renderContent = () => {
    switch(pageNav) {
      case "":
        return <RenderMain />;
      case "gift":
        return <RenderGift />;
      case "health":
        return <HealthySavings />;  
      case "help":
        return <Help />;    
      case "alloffers":
        return <AllOffers />  
      default:
        return <RenderMain />    
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.containerThree}>
            <div className={styles.containerTwo} onClick={goHome}>
              <img src={PostpaidLogo} alt="Company Pics" />
              <p className={styles.textOne}>Postpaid</p>
            </div>

            {/* <p className={styles.textTwo}>Who we are?</p>

            <p className={styles.textTwo}>How to use</p> */}
          </div>

          <>
            <img onClick={() => setIsOpen(true)} src={androidMenu} alt="android menu bar" className={styles.menuBar} />
            <div className={styles.containerFour}>
            <p className={styles.textThree} onClick={() => navigate('/')}>Consumer</p>
              <p className={styles.textThree} onClick={() => navigate('/business')}>Business</p>

              <Divider style={{height: "30px"}} orientation="vertical" variant="middle" flexItem />

              <button className={styles.btnLogin}  onClick={joinNow}>Join Now</button>

              {/* <button className={styles.btnLogin} onClick={() => navigate('/login')}>Login</button>

              <button className={styles.btnLoginOutlined} onClick={() => navigate('/signup')}>Sign Up</button> */}
            </div>
          </>
        </div>

        <Categories />

        {renderContent()}

        <Footer />
      </div>
      <JoinNow openJoinNow={openJoinNow} setOpenJoinNow={setOpenJoinNow} />
      <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}

export default Landing